import React, { Component } from 'react'
import { TabBar } from 'antd-mobile'
import '../styles/tabBar.less'
import { browserHistory } from 'react-router'

// const HOME_DEF = require('../images/tabbar_home_def.png')
import HOME_DEF from '../images/tabbar_home_def.png'
import HOME_PRE from '../images/tabbar_home_pre.png'
import KECHEN_DEF from '../images/tabbar_kechen_def@2x.png'
import KECHEN_PRE from '../images/tabbar_kechen_pre@2x.png'
import RENWU_DEF from '../images/tabbar_renwu_def.png'
import RENWU_PRE from '../images/tabbar_renwu_pre.png'
import MY_DEF from '../images/tabbar_my_def.png'
import MY_PRE from '../images/tabbar_my_pre.png'

const tabBarArr = [
  { title: '首页', key: 'Home', icon: HOME_DEF, selectedIcon: HOME_PRE, route: '/' },
  { title: '课程', key: 'Course', icon: KECHEN_DEF, selectedIcon: KECHEN_PRE, route: '/course' },
  { title: '任务', key: 'Task', icon: RENWU_DEF, selectedIcon: RENWU_PRE, route: '/train' },
  {
    title: '我的',
    key: 'My',
    icon: MY_DEF,
    selectedIcon: MY_PRE,
    route: '/personcenter',
    dev: () => {
      browserHistory.push('/dev_info')
    },
  },
]

export default class TabBarComponent extends Component {
  timer = null
  clickTimes = {}

  render() {
    const { selectedTab } = this.props
    return (
      <div className='tabBar-box'>
        <TabBar unselectedTintColor='#979DB2' tintColor='#549EF8' barTintColor='white'>
          {tabBarArr.map(item => (
            <TabBar.Item
              title={item.title}
              key={item.key}
              icon={
                <div
                  style={{
                    width: '22px',
                    height: '22px',
                    background: `url(${item.icon}) center center /  21px 21px no-repeat`,
                  }}
                />
              }
              selectedIcon={
                <div
                  style={{
                    width: '22px',
                    height: '22px',
                    background: `url(${item.selectedIcon}) center center /  21px 21px no-repeat`,
                  }}
                />
              }
              onPress={() => {
                clearTimeout(this.timer)
                this.timer = setTimeout(() => {
                  this.clickTimes[item.key] = 0
                }, 0.5 * 1000)
                if (!this.clickTimes[item.key]) this.clickTimes[item.key] = 0
                this.clickTimes[item.key]++
                if (this.clickTimes[item.key] > 6) {
                  this.clickTimes[item.key] = 0
                  item.dev && item.dev()
                  return
                }
                browserHistory.push(item.route)
              }}
              selected={selectedTab === item.key}
            ></TabBar.Item>
          ))}
        </TabBar>
      </div>
    )
  }
}
