import React, { Component } from 'react'
import '../styles/unDevelop.less'
import ONLINE from '../images/online.png'



export default class RankItem extends Component {
  static defaultProps = {
    backImg: ONLINE,
    text: '功能即将上线'
  }
  render() {
    const {backImg, text} = this.props
    return (
      <div className='will-online-page'>
        <img src={backImg} className="online-img" alt=""/>
        <p className="online-text">{text}</p>
      </div >
    )
  }
}