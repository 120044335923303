import React, { Component } from 'react'
import { Tabs } from 'antd-mobile'
import RankItem from '@/components/RankItem'
import '@/styles/rankPage.less'
import { fetchUserRank } from '@/apis/'
import { getUserInfo } from '@/util/common'

const tabs = [
  { title: '学分', gist: 'credit', label: '学分', formatter: v => v / 1000 },
  { title: '积分', gist: 'xp', label: '积分', formatter: v => v / 1000 },
  {
    title: '学时',
    gist: 'duration',
    label: '学时',
    formatter: v => {
      v = Number.parseFloat(v)
      const h = Math.floor(v / 60) + '小时'
      const m = Math.floor(v % 60) + '分钟'

      return h + m
    },
  },
]

export default class TaskPage extends Component {
  state = {
    gist: 'credit', //xp为级分，credit为学分
    label: '学分',
    rankList: {
      credit: [],
      xp: [],
    },
    own: {},
  }
  async componentDidMount() {
    document.title = '排行榜'
    this._getRankList({ gist: this.state.gist, label: this.state.label })
  }

  _getRankList = async data => {
    let { rankList } = this.state
    const res = await fetchUserRank({ account: data.gist })
    rankList[data.gist] = res.data.map(_ => ({ ..._, value: _[data.gist] }))

    const userinfo = getUserInfo()
    const index = rankList[data.gist].findIndex(_ => _.user_id === userinfo.id)
    const t = rankList[data.gist][index] || {}

    this.setState({
      rankList: rankList,
      gist: data.gist,
      label: data.label,
      own: {
        [`${data.gist}.ranking`]: index + 1,
        [data.gist]: t.value,
      },
    })
  }

  tabChange(tab, index) {
    this._getRankList({ gist: tab.gist, label: tab.label })
  }

  render() {
    const { rankList, gist, own, label } = this.state

    const list = formatter =>
      rankList[gist].map((item, index) => (
        <RankItem
          key={item.user_id}
          index={index}
          item={item}
          label={label}
          formatter={formatter}
        />
      ))

    return (
      <div className='rank-page'>
        <Tabs
          tabs={tabs}
          initialPage={0}
          onChange={(tab, index) => {
            this.tabChange(tab, index)
          }}
        >
          {tabs.map((_, i) => (
            <div key={i} style={{ width: '100%', height: '100%' }}>
              <MyRank gist={gist} own={own} label={label} formatter={_.formatter} />
              {list(_.formatter)}
            </div>
          ))}
        </Tabs>
      </div>
    )
  }
}

class MyRank extends Component {
  render() {
    const { own, gist, label, formatter } = this.props
    return (
      <div className='my-rank'>
        <p className='rank'>
          我的排名：<em>{own[`${gist}.ranking`] || '-'}</em>
        </p>
        <p className='score'>
          当前{label}：<em>{typeof own[gist] === 'number' ? formatter(own[gist]) : '-'}</em>
        </p>
      </div>
    )
  }
}
