import React, { Component } from 'react'
import CourseItem from '@/components/CourseItem'
import { fetchCategories, fetchRecommendCourses } from '@/apis/'
import { PullToRefresh, ListView, ActivityIndicator } from 'antd-mobile'
import { promisify } from '../util/common'

const pageSize = 10

export default class LastestCoursePage extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    })
    this.hasMore = false
    this.state = {
      dataSource,
      recommendList: [],
      useBodyScroll: true,
      refreshing: false,
      isLoading: false,
      hasMore: false,
      height: document.documentElement.clientHeight,
      total: 0,
      page: 0,
      showFooter: false, //如果数据只有几条，则不显示footer
    }
  }

  pss = promisify(this.setState.bind(this))

  async componentDidMount() {
    document.title = '热门推荐'
    await this.fetchCourse({ page: 0 })
  }

  fetchCourse = async ({ page, isAdd }) => {
    await this.pss({ isLoading: true })

    try {
      const {
        data: { id },
      } = await fetchCategories()

      let { data: recommendList, headers } = await fetchRecommendCourses(id, uqb => {
        uqb.setPageSize(pageSize)
        uqb.go2page(page)
        uqb.addQuery('featured', 'true', 'eq', 'boolean')
      })

      const total = Number(headers.count) || 0

      this.hasMore = (page + 1) * pageSize < total

      if (isAdd) {
        recommendList = this.state.recommendList.concat(recommendList)
      }

      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(recommendList),
        // height: hei,
        refreshing: false,
        isLoading: false,
        recommendList,
        hasMore: this.hasMore,
        showFooter: total >= pageSize,
        page,
      })
    } finally {
      await this.pss({ isLoading: false })
    }
  }

  onRefresh = async () => {
    await this.pss({ refreshing: true })

    await this.fetchCourse({ page: 0 })

    await this.pss({ refreshing: false })
  }

  onEndReached = async event => {
    let { page, hasMore, isLoading } = this.state
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (!hasMore || isLoading) {
      return
    }

    await this.fetchCourse({
      page: page + 1,
      isAdd: true,
    })
  }

  clickItem(item) {
    let id = item.id
    this.props.router.push(`/course/detail/${id}`)
  }

  render() {
    const { showFooter } = this.state
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          background: '#F3F4F8',
          height: 10,
        }}
      />
    )
    const row = (rowData, sectionID, rowID) => {
      let item = rowData
      return (
        <CourseItem
          style={{ wraperStyle: { marginBottom: 0 } }}
          onClick={item => this.clickItem(item)}
          key={item.id}
          item={{
            ...item,
            date: item.updateAt,
            company: item.publisher.name,
            myAuthor: item.author ? item.author.name : '',
            myPublisher: item.publisher.name,
          }}
        />
      )
    }

    return (
      <div className='lastest-course' style={{ height: '100%', background: '#F3F4F8' }}>
        {/* {listView} */}
        {/* 
          renderHeader={() => <span>Pull to refresh</span>}
          
        */}
        <ListView
          key={this.state.useBodyScroll ? '0' : '1'}
          ref={el => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderSeparator={separator}
          renderRow={row}
          useBodyScroll={this.state.useBodyScroll}
          style={
            this.state.useBodyScroll
              ? {
                  height: '100%',
                }
              : {
                  height: this.state.height,
                  border: '1px solid #ddd',
                  margin: '5px 0',
                }
          }
          renderFooter={() => {
            if (showFooter) {
              return (
                <div
                  style={{
                    padding: 10,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {this.state.isLoading ? (
                    <ActivityIndicator text='loading...' />
                  ) : this.state.hasMore ? (
                    '上拉加载更多'
                  ) : (
                    '没有更多了'
                  )}
                </div>
              )
            }
          }}
          pullToRefresh={
            <PullToRefresh refreshing={this.state.refreshing} onRefresh={this.onRefresh} />
          }
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10}
          pageSize={pageSize}
        />
      </div>
    )
  }
}
