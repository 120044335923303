import React, { Component } from 'react'
import '../styles/author.less'

export default class MyMessage extends Component {
  state = {
    author: {}
  }
  componentDidMount() {
    document.title = '讲师简介'
    //到时候从localStorage里面去取author相关信息
    let tmp = localStorage.getItem('author')
    let author = {}
    if (tmp) {
      author = JSON.parse(tmp)
    }
    this.setState({ author })
  }
  render() {
    const { author } = this.state
    // const author = {
    //   avatar: "http://kyzx.oss-cn-beijing.aliyuncs.com/48d926997ad24259bb7964b1e585b1cf.png",
    //   description: "213123213",
    //   id: 21,
    //   name: "555",
    //   orgId: 0,
    // }
    return (
      <div className="author-page">
        <header className='header'>
          <img src={author.avatar} alt="avatar" />
          <p>{author.name}</p>
        </header>
        <div className="content">
          <div className="sub-title">讲师简介：</div>
          <div className='cont'>
            {author.description}
            {/* 研究员，获省部级以上科技进步奖与优秀新产品奖X项，主持起草国家标准X项、行业标准X项，获授权专利X件、在国家级核心刊物上发表论文X余篇。 */}
          </div>
        </div>
      </div>
    )
  }
}
