import React, { Component } from 'react'
import { Button } from 'antd-mobile';
import '@/styles/loadFail.less'

import LOAD_FAIL_IMG from '../images/empty_pic_date@2x.png'

export default class LoadFail extends Component {
  render() {
    return (
      <div className="load-fail-box">
        <div className="content-box">
          <img src={LOAD_FAIL_IMG} alt="#" />
          <p>数据加载失败</p>
          <Button type="primary">重试</Button>
        </div>
      </div>
    )
  }
}