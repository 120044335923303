import React, { Component } from 'react'
import '../styles/adverImage.less'
import { browserHistory } from 'react-router'
import axios from 'axios'
import Apis from '../apis/index'
import { applyToken } from '../util/wxconfig'
// import { Toast } from 'antd-mobile'

const AuthApi = Apis.AuthApi

export default class Adver extends Component {
  componentDidMount() {
  }
  render() {
    return (
      <div className="adver-image-box">
        <img src="http://kyzx.oss-cn-beijing.aliyuncs.com/0cb90ca22885484f8cf3c933497408a1.png" alt="" />
      </div>
    )
  }
}