import React, { Component } from 'react'
import Apis from './apis'
import { wxConfig, wxGetNetworkType } from './util/wxconfig'
import Socket from './util/socket'
import { getToken } from '@/util/common'
import { get, set } from './services/storage'
import config from './config'

const AuthApi = Apis.AuthApi
function getHistoryRoute() {
  let tmp = localStorage.getItem('historyRoute')
  return tmp ? JSON.parse(tmp) : []
}
export default class App extends Component {
  state = {
    showAdver: false,
  }
  componentWillReceiveProps(nextProps, state) {
    let historyRoute = getHistoryRoute()
    if (nextProps.location.pathname !== this.props.location.pathname) {
      let routeName = this.props.location.pathname
      this.dealHistoryRoute(historyRoute, routeName)
    }
  }

  setOrgId = () => {
    const { search } = window.location
    const orgIdString = search
      .replace(/^\?/, '')
      .split('&')
      .find(_ => _.startsWith('orgId='))
    const orgId = orgIdString?.split('=')[1] || window[config.orgIdKey]
    if (!orgId) return
    window[config.orgIdKey] = orgId
  }

  dealHistoryRoute(historyRoute, routeName) {
    //已经存在的话则放到最后一位位
    let index = historyRoute.indexOf(routeName)
    if (index > -1) {
      historyRoute.splice(index, 1)
    }
    historyRoute.push(routeName)
    historyRoute = historyRoute.slice(0, 10)
    localStorage.setItem('historyRoute', JSON.stringify(historyRoute))
  }

  componentDidMount() {
    let route = this.props.location.pathname
    let whiteList = ['/login', '/forgetPw','/one']
    this.setOrgId()
    if (whiteList.includes(route)) {
      return
    }

    if (!getToken()) {
      this.props.router.replace('login')
      return
    }

    AuthApi.authToken().then(() => {
      //每次刷新页面的时候且请求token验证完毕后
      let socket = new Socket()
      window.socket = socket
      //获取连接的网络类型
      wxConfig()
      wxGetNetworkType().then(res => {
        console.log('home', res)
        window.networkType = res
      })
    }) //401结果在拦截器里统一处理的
    if (window.history.length === 1) {
      const pathname = this.props.location.pathname
      let historyRoute = getHistoryRoute()
      if (pathname !== '/') {
        this.dealHistoryRoute(historyRoute, pathname)
        if (historyRoute && historyRoute.length) {
          historyRoute.forEach(item => {
            window.history.pushState({ route: item }, item, `/${item}`)
          })
        }
      }
    }
    window.addEventListener('popstate', function (e) {
      // do something...
    })
  }
  render() {
    return <div style={{ height: '100%' }}>{this.props.children}</div>
  }
}
