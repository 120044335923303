import React, { Component } from 'react'
import { Carousel, Toast } from 'antd-mobile'
import { browserHistory, Link } from 'react-router'
import '@/styles/homePage.less'
import TabBarComponent from '@/components/TabBarComponent'
import SearchBox from '@/components/SearchBox'
import Apis, { fetchCategories, fetchRecommendCourses, getCourseList } from '@/apis/'
import { videoTime, getOrgIdOnDomin, getToken } from '../util/common'
import IMG_COURSE from '../images/home_icon_kechen@2x.png'
import IMG_TEST from '../images/home_icon_test@2x.png'
import IMG_RANK from '../images/home_icon_paihang@2x.png'
import IMG_TMP1 from '../images/tmp1.png'
import IMG_NO_SEARCH from '../images/empty_pic_search@2x.png'
import UQB from '../util/UQB'

const UserApi = Apis.UserApi
const MessageApi = Apis.MessageApi

const navs = [
  { img: IMG_COURSE, text: '课程中心', url: '/course' },
  { img: IMG_TEST, text: '考试中心', url: '/test' },
  { img: IMG_RANK, text: '排行榜', url: '/rank' },
]

export default class Home extends Component {
  state = {
    slides: [],
    imgHeight: 176,
    lastestList: [],
    searchData: [],
    showSearch: false,
    recommendList: [],
    messages: 0,
    showMessage: true,
    title: '', //searchKey搜索的关键字
    theme: {},
    user_profile: {},
  }
  async componentDidMount() {
    if (!getToken()) {
      return
    }

    const theme = await UserApi.getTheme(getOrgIdOnDomin())
    const user_profile = await UserApi.queryUserProp()

    document.title = theme.title || '全员学习'
    this.setState({ theme, user_profile })
    const {
      data: { id },
    } = await fetchCategories()

    const uqb = new UQB()
    uqb.setSort('updateAt', 'desc', 'double')

    const [res0, res1] = await Promise.all([fetchRecommendCourses(id), getCourseList(id, uqb)])

    const recommendList = res0.data.slice(0, 4)
    const lastestList = res1.data.slice(0, 4)

    this.setState({ recommendList, lastestList })
    UserApi.getCommonConfig().then(res => {
      let slides = res.slides || []
      this.setState({
        slides,
      })
    })
    MessageApi.getMyMessageList().then(res => {
      // let messages = res.length || 0
      let messages = 0
      res.forEach(item => {
        if (item.unread) {
          messages++
        }
      })
      this.setState({
        messages: messages,
      })
    })
  }
  onSubmit = async title => {
    const {
      data: { id },
    } = await fetchCategories()

    const uqb = new UQB()
    uqb.addQuery('title', encodeURIComponent(`%${title}%`), 'like', 'string')

    const { data: searchData } = await getCourseList(id, uqb)

    this.setState({
      searchData,
      title,
      showSearch: true,
    })
  }
  onFocus() {
    // this.setState({ showSearch: true })
    this.setState({ showMessage: false })
  }
  onCancel() {
    this.setState({ showSearch: false, showMessage: true })
  }
  clickSearchItem(item) {
    let id = item.id
    this.props.router.push(`/course/detail/${id}`)
  }
  clickItem(item) {
    let id = item.id
    this.props.router.push(`/course/detail/${id}`)
  }
  gotoPage(item) {
    // console.log(item)
    window.location.href = item.link
  }
  render() {
    const {
      searchData,
      showSearch,
      lastestList,
      recommendList,
      title,
      messages,
      showMessage,
      theme,
      user_profile,
    } = this.state
    let recommendListView = recommendList.map((item, index) => (
      <Item
        key={item.id}
        onClick={item => this.clickItem(item)}
        item={{
          ...item,
          time: item.videoDuration,
          author: item.author ? item.author.name : '',
          company: item.publisher.name,
        }}
      />
    ))
    let lastestListView = lastestList.map((item, index) => (
      <Item
        key={item.id}
        onClick={item => this.clickItem(item)}
        item={{
          ...item,
          time: item.videoDuration,
          author: item.author ? item.author.name : '',
          company: item.publisher.name,
        }}
      />
    ))

    let x = (
      <div style={{ visibility: 'hidden', height: 0 }}>
        <form
          ref='fff'
          action={theme.exam || 'http://educenterexam.coreland.net/m/login.aspx'}
          method='POST'
        >
          <input type='text' readOnly={true} value={user_profile.employeeNo} name='txtUserName' />
          <input type='password' readOnly={true} value='123456' name='txtPwd' />
          <input
            type='hidden'
            name='__VIEWSTATE'
            value={
              theme.viewState ||
              '/wEPDwULLTE4NzExMjM1MTdkZDdxR4I2SOLM12lF2rR4Af+5MhXt7TXmAXUDSu2Ll7Wa'
            }
          />{' '}
          <input
            type='hidden'
            name='__VIEWSTATEGENERATOR'
            value={theme.viewStateGenerator || 'A8FDB752'}
          />
          <input
            type='hidden'
            name='__EVENTVALIDATION'
            value={
              theme.eventValidation ||
              '/wEdAAPrwujmnVZOKeButFhImguvY3plgk0YBAefRz3MyBlTcDPSlu16Yx4QbiDU+dddK1MpRaTol0CBZgM2DbLgtvLpRwWyckGEjp4Ps3q5y1XiLQ=='
            }
          />
          <button ref='s'>提交</button>
        </form>
      </div>
    )
    return (
      <div className='home-page'>
        {x}
        {/* 搜索的数据显示 */}
        {showSearch ? (
          <div className='search-cont'>
            {searchData.length ? (
              <div className='have-data'>
                {searchData.map((item, index) => {
                  return (
                    <SearchItem
                      key={item.id}
                      item={item}
                      onClick={item => this.clickSearchItem(item)}
                      content={item.title}
                      searchKey={title}
                    />
                  )
                })}
              </div>
            ) : (
              <NoData />
            )}
          </div>
        ) : null}

        <div className='content-box'>
          <div className='search-header'>
            <SearchBox
              placeholder={'请输入你想要搜索的内容'}
              onSubmit={value => this.onSubmit(value)}
              name='home-search-box'
              onFocus={() => this.onFocus()}
              onCancel={() => this.onCancel()}
            />
            {showMessage ? (
              <div
                className='badge-box'
                onClick={() => {
                  this.props.router.push('/message')
                }}
              >
                {messages > 0 ? <em>{messages}</em> : null}
              </div>
            ) : null}
          </div>

          {/* 轮播图 */}
          <div className='carousel-box'>
            {this.state.slides.length ? (
              <Carousel
                autoplay
                infinite
                autoplayInterval={4000}
                beforeChange={(from, to) => {}}
                afterChange={index => {}}
              >
                {this.state.slides.map((val, index) => (
                  <div
                    onClick={() => {
                      console.log({ val })
                      switch (val?.link?.type) {
                        case 'Course': {
                          this.props.router.push(`/course/detail/${val?.link?.id}`)
                          break
                        }
                        default: {
                          break
                        }
                      }
                      // this.gotoPage(val)
                    }}
                    key={index}
                    style={{ display: 'block' }}
                  >
                    <img
                      src={val.url}
                      alt='#'
                      style={{
                        width: '100%',
                        height: '100%',
                        verticalAlign: 'top',
                      }}
                      onLoad={() => {
                        // fire window resize event to change height
                        // window.dispatchEvent(new Event('resize'));
                        this.setState({ imgHeight: 'auto' })
                      }}
                    />
                  </div>
                ))}
              </Carousel>
            ) : null}
          </div>
          <section className='content-wraper'>
            {/* 导航 */}
            <section className='nav-box'>
              {navs.map((item, index) => (
                <div
                  className='item'
                  key={index}
                  onClick={() => {
                    if (item.text === '考试中心') {
                      if (!theme.exam) {
                        browserHistory.push('/wip')
                        return
                      }
                      this.refs.fff.submit()
                    } else {
                      browserHistory.push(item.url)
                    }
                  }}
                >
                  <img src={item.img} alt={item.text} />
                  <em>{item.text}</em>
                </div>
              ))}
            </section>
            {/* 热门推荐 */}
            <section className='hot-box'>
              <div className='header'>
                <div className='title'>课程推荐</div>
                <Link to='/recommend/course' className='more'>
                  更多>
                </Link>
              </div>
              <div className='lists'>{recommendListView}</div>
            </section>
            {/* 最新课程 */}
            <section className='hot-box' style={{ paddingTop: 0 }}>
              <div className='header'>
                <div className='title'>最新课程</div>
                <Link to='/lastest/course' className='more'>
                  更多>
                </Link>
              </div>
              <div className='lists'>{lastestListView}</div>
            </section>
          </section>
          <TabBarComponent selectedTab={'Home'} />
        </div>
      </div>
    )
  }
}

class Item extends Component {
  static defaultProps = {
    item: {
      //临时的显示数据
      title: '保证责任到位的方法…',
      cover: IMG_TMP1,
      author: '钟国梅',
      company: '钒钛股份',
      played: 234,
      time: '23:18',
    },
  }
  onClick() {
    const { item } = this.props
    if (!item.progress) {
      item.progress = {}
    }
    this.props.onClick && this.props.onClick(item)
  }
  render() {
    const { item } = this.props
    return (
      <div className='list' onClick={() => this.onClick()}>
        <div className='img-box'>
          <img src={item.cover} alt='#' />
          <div className='play-mask'>
            <em>{item.played}</em>
            <i>{item.time ? videoTime(item.time) : null}</i>
          </div>
        </div>
        <p className='title ellipsis'>{item.title}</p>
        <p className='auth-comp'>
          <em>{item.author}</em>
          <span>{item.company}</span>
        </p>
      </div>
    )
  }
}

class NoData extends Component {
  render() {
    return (
      <div className='no-search'>
        <img src={IMG_NO_SEARCH} alt='' />
        <p>没有搜索到相关内容</p>
      </div>
    )
  }
}

class SearchItem extends Component {
  onClick() {
    const { item } = this.props
    if (!item.progress) {
      item.progress = {}
    }
    localStorage.setItem('courseItem', JSON.stringify(item))
    this.props.onClick && this.props.onClick(item)
  }
  render() {
    const { content, searchKey } = this.props
    let viewItem = `<p>${content}</p>`
    let searchKeyToHtml = `<em style="color: #549EF8">${searchKey}</em>`
    if (content && searchKey) {
      viewItem = viewItem.replace(searchKey, searchKeyToHtml)
    }
    return (
      <div
        className='search-item top-line'
        dangerouslySetInnerHTML={{ __html: viewItem }}
        onClick={() => this.onClick()}
      ></div>
    )
  }
}
