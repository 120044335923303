import { axiosget } from "../config/axios";

/* eslint-disable */
// export const openId = 'oA6EOwmyE-RQy8cYEDMWYJp1rv6c'
// export const wx_token = '25_hSfnvmWDZu_wet5ghpPE8uhAoZQ_pFtz6WcqpukClym3AI5cdXV-QSKJuZYscPDbrZmZG_DvB0KXPcd8SYXV5A-6G8jeakLtXs4b75nAYqk'
//存取openId, wx_token
export function applyToken(openId, wxToken) {
  if (openId) {
    localStorage.setItem(openId, openId)
  }
  if (wxToken) {
    localStorage.setItem(wxToken, wxToken)
  }
}

// 微信sdk获取设备信息
export function wxGetNetworkType() {
  return new Promise((resolve, reject) => {
    wx.ready(() => {
      wx.getNetworkType({
        success: function (res) {
          console.log('getNetworkType', res)
          var networkType = res.networkType; // 返回网络类型2g，3g，4g，wifi
          resolve(networkType)
        }
      })
    })
  });

}



/**
 *
 * 微信sdk注入配置
 * 
 */
export function wxConfig() {
  if (!wx) {
    return
  }
  let url = window.location.href
  // url = url.split("#")[0]
  // url = url.split("#")[0] + '?#' + url.split("#")[1]
  url = encodeURIComponent(url)
  return axiosget('/authinterface/v1/wx/jsapi?url=' + url).then((res) => {
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: 'wx025f81fc89447cdc', // 必填，公众号的唯一标识  //wx025f81fc89447cdc
      timestamp: res.timestamp,// 必填，生成签名的时间戳
      nonceStr: res.nonceStr, // 必填，生成签名的随机串
      signature: res.signature,// 必填，签名
      jsApiList: [
        'getNetworkType'
      ] // 必填，需要使用的JS接口列表
    })
  })

  // let url = encodeURIComponent(window.location.href)
  // return axios.get('/app/public/sign?url=' + url).then(res => {
  // wx.config({
  // debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
  // appId: '', // 必填，公众号的唯一标识
  // timestamp: new Date(),// 必填，生成签名的时间戳
  // nonceStr: '', // 必填，生成签名的随机串
  // signature: '',// 必填，签名
  //   ...res,
  //   jsApiList: [
  //     'updateAppMessageShareData',
  //     'updateTimelineShareData',
  //     'onMenuShareTimeline',
  //     'onMenuShareAppMessage',
  //     'onMenuShareQQ',
  //     'chooseWXPay'
  //   ] // 必填，需要使用的JS接口列表
  // })
  // }).catch(_ => {
  //   Toast.clear()
  // })
}