import React, { Component } from 'react'
// import CourseItem from '@/components/CourseItem1'
import TabBarComponent from '@/components/TabBarComponent'
import '@/styles/taskPage.less'
import '@/styles/trainPage.less'
// import Apis from '@/apis/'
// const TrainApi = Apis.TrainApi


// const tabs = [
//   // { title: '未开始', status: 'unstart' },
//   { title: '进行中', status: 'studying' },
//   { title: '已完成', status: 'complete' }
// ]
// const compulsory = false
export default class TaskPage extends Component {
  state = {
    trainList: [],
    status: 'studying',
  }
  tabClick(item) {
    this.setState({
      status: item.status,
    })
  }
  componentDidMount() {
    document.title = '培训任务'
    // TrainApi.getTrainPlan().then((res) => {
    //   let trainList = res.map((item) => {
    //     if (item.percent && item.percent === 100) {
    //       item.status = 'complete'
    //     } else {
    //       item.status = 'studying'
    //     }
    //     return item
    //   })
    //   this.setState({
    //     trainList
    //   })
    // })
  }
  clickItem(item) {
    //这里是每个培训计划列表，列表暂时用的课程列表样式，但是item应该是一样
    let id = item.id
    this.props.router.push(`/train/detail/${id}`)
  }
  render() {
    let { status, trainList } = this.state
    // let tabView = tabs.map((item, index) => {
    //   return <div key={index} className={status === item.status ? 'selected tab-item' : 'tab-item'} onClick={() => this.tabClick(item)} >{item.title}</div>
    // })
    let list = []
    trainList.forEach((item) => {
      if (item.status === status) { //用于过滤状态
        list.push(item)
      }
    })
    // let listView = list.map((item, index) => {
    //   // status: status,
    //   return <CourseItem key={index} item={{ ...item, date: item.endAt, isTrain: true, title: item.name, showEnd: true, showProgress: true, percent: item.percent }} onClick={(item) => this.clickItem(item)} />
    // })
    return (
      <div className='task-page' style={{ height: '100%' }}>
        <div className='train_page'>请联系管理员下载APP</div>
        {/* <div className="tab-box bottom-line" style={{justifyContent: 'space-around'}}>
          {tabView}
        </div>
        <div>
          {listView}
        </div> */}
        <TabBarComponent selectedTab={'Task'} />
      </div>
    )
  }
}