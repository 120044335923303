import React, { Component } from 'react';
import { InputItem, Toast, NavBar, Icon } from 'antd-mobile';
import Apis from '../apis/index';
import { browserHistory } from 'react-router';
import regexp from '../util/regexp';
import { getUserInfo } from '@/util/common';

const UserApi = Apis.UserApi;

export default class ResetPw extends Component {
    constructor(props) {
        super(props);
        // this.info = this.props.info
        this.state = {
            code: '',
            newPw: '',
            newPwAgain: '',
        };
    }
    validatePw() {
        let value = this.state.newPw.toString().trim();
        let reg = regexp.regPass;
        if (!reg.test(value)) {
            Toast.info('密码为8位数字，字符组合', 2, null, false);
            return false;
        }
        return true;
    }
    validatePwAgain() {
        let newPw = this.state.newPw.toString().trim(),
            newPwAgain = this.state.newPwAgain.toString().trim();
        if (newPw !== newPwAgain) {
            Toast.info('两次输入不一致', 2, null, false);
            return false;
        }
        return true;
    }
    sure() {
        if (!this.validatePw() || !this.validatePwAgain()) {
            return;
        }
        let newPasswordA = this.state.newPw.toString().trim(),
            code = this.state.code.toString().trim(),
            newPasswordB = this.state.newPwAgain.toString().trim();
        UserApi.modifyPw({
            newPasswordA,
            code,
            newPasswordB,
        }).then(res => {
            Toast.info('修改成功', 2, null, false);
            // sessionStorage.setItem('userInfo', '');
            localStorage.setItem('userInfo', '');
            browserHistory.push('/login');
        });
        //请求借口,成功后跳转登录
    }
    clickRight() {
        const userInfo = getUserInfo();
        let phone = userInfo.username;
        let value = phone.toString().trim();
        let reg = /^1([3|4|5|7|8|])\d{9}$/;
        if (!reg.test(value)) {
            Toast.info('手机号不正确', 3, null, false);
            return;
        }
        UserApi.sendResetPassCode({
            username: value,
        }).then(res => {
            Toast.info('发送成功', 3, null, false);
        });
    }
    render() {
        const { showReset } = this.props;
        const userInfo = getUserInfo();
        return (
            <div className='reset-pw-box'>
                <NavBar
                    mode='light'
                    key={1}
                    className='nav-reset'
                    icon={
                        <div className='nav-left'>
                            <Icon type='left' />
                            <em>修改密码</em>
                        </div>
                    }
                    onLeftClick={() => showReset(false)}
                    rightContent={[
                        <div key={1} onClick={() => this.sure()}>
                            确认
                        </div>,
                    ]}
                />
                <p className='tips'>密码为8-18位的数字，字符组合</p>
                <InputItem className='reset-item' disabled value={userInfo.username} extra={<RightButton clickRight={() => this.clickRight()} flag={true} />}>
                    手机号：
                </InputItem>
                <InputItem clear placeholder='请输入验证码' className='reset-item' value={this.state.code} onChange={value => this.setState({ code: value })}>
                    验证码：
                </InputItem>
                <InputItem clear placeholder='请输入新密码' type='password' className='reset-item' value={this.state.newPw} onChange={value => this.setState({ newPw: value })}>
                    新密码：
                </InputItem>
                <InputItem
                    clear
                    type={'password'}
                    placeholder='再次填写确认'
                    className='reset-item'
                    value={this.state.newPwAgain}
                    onChange={value => {
                        this.setState({ newPwAgain: value });
                    }}
                >
                    确认密码：
                </InputItem>
                {/* <div className="button-box">
          <Button type="primary" inline size="small" style={{ marginRight: '4px' }}>修改</Button>
          <Button type="default" inline size="small" >取消</Button>
        </div> */}
            </div>
        );
    }
}

class RightButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canClick: true,
            text: '获取验证码',
        };
    }
    clickRight() {
        const { clickRight, flag } = this.props;
        if (flag && this.state.canClick) {
            this.countTime();
            clickRight();
        }
    }
    countTime() {
        let time = 60;
        this.setState({
            canClick: false,
            text: time + 's',
        });
        this.timer = setInterval(() => {
            time--;
            this.setState({
                text: time + 's',
            });
            if (time <= 0) {
                clearInterval(this.timer);
                this.setState({
                    canClick: true,
                    text: '获取验证码',
                });
            }
        }, 1000);
    }
    render() {
        const { flag } = this.props;
        return (
            <div className='right-box' onClick={() => this.clickRight()}>
                <span style={{ color: '#549EF8' }}>{this.state.text}</span>
            </div>
        );
    }
}
