import React, { Component } from 'react'
import { List } from 'antd-mobile'
import { browserHistory } from 'react-router'
import Apis from '@/apis/'
import TabBarComponent from '@/components/TabBarComponent'
import { fetchRankings, fetchUserBalance, fetchCredits } from '../apis/index'
import { get } from '../services/storage'
import CONFIG from '../config'
import moment from 'moment'
import '../styles/personcenter.less'
import Study from '../images/my_icon_xuexi.png'
import Train from '../images/my_icon_dangan.png'
import Collection from '../images/my_icon_shouchang.png'
import FeedBack from '../images/my_icon_yijian.png'
import WHITE_ARROW from '../images/my_arrow_white.png'
import small_black_arrow from '../images/arrow_small@2x.png'
import MessageIcon from '../images/home_icon_message@2x.png'

const UserApi = Apis.UserApi
const MessageApi = Apis.MessageApi

const Item = List.Item
export default class PersonCenter extends Component {
  state = {
    info: {},
    messages: 0,
    ranking: {},
    balance: {},
    continuePunchDay: 0,
  }

  async componentDidMount() {
    document.title = '个人中心'
    let [info, messagelist] = await Promise.all([
      UserApi.queryUserProp(),
      MessageApi.getMyMessageList(),
    ])

    ;['xp', 'duration', 'credit'].map(this.fetchRankings)
    ;['xp', 'duration', 'credit', 'sh'].map(this.fetchUserBalance)
    this.fetchContinuePunchDay()

    const messages = messagelist.reduce((count, item) => {
      if (item.unread) {
        count++
      }

      return count
    }, 0)
    this.setState({
      messages,
      info,
    })
  }

  fetchUserBalance = async account => {
    const userInfo = JSON.parse(get(CONFIG.userInfo))
    const { data } = await fetchUserBalance(userInfo.id, account)
    this.setState({
      balance: {
        ...this.state.balance,
        [account]: data.balance,
      },
    })
  }

  /**
   * @description
   * @param {"xp" | "duration" | "credit" | "online"} account
   */
  fetchRankings = async account => {
    const userInfo = JSON.parse(get(CONFIG.userInfo))
    const { data } = await fetchRankings(account)
    this.setState({
      ranking: {
        ...this.state.ranking,
        [account]: data.findIndex(_ => _.user_id === userInfo.id) + 1,
      },
    })
  }

  fetchContinuePunchDay = async () => {
    let d = moment()
    let continuePunchDay = 0
    let r = 0
    do {
      const { data } = await fetchCredits(
        'xp',
        d.startOf('day').toDate().getTime(),
        d.endOf('day').toDate().getTime()
      )
      r = data.filter(_ => _.remark.includes('签到')).length
      continuePunchDay += r
      d = d.add(-1, 'day')
    } while (r > 0)
    this.setState({
      continuePunchDay,
    })
  }

  render() {
    const { ranking, balance, continuePunchDay } = this.state

    return (
      <div className='my-page'>
        <section className='header-box'>
          {/* header */}
          <section className='header' onClick={() => browserHistory.push('/config')}>
            <img
              src={this.state.info.avatar + '?r=' + Math.random()}
              alt='avatar'
              className='avatar'
            />
            <div className='info-box'>
              <span className='phone'>{this.state.info.name}</span>
              <span>{this.state.info.company}</span>
              <span>{this.state.info.department}</span>
            </div>
            <img src={WHITE_ARROW} className='arrow-icon' alt='arrow' />
          </section>
          {/* content */}
          <div className='haeder_content'>
            <section className='rank-box'>
              <div className='rank_content_wrap' onClick={() => browserHistory.push('/report')}>
                <div className='rank_header'>
                  <span>我的学习报告</span>
                  <img src={small_black_arrow} className='rank_arrow' alt='' />
                </div>
                <div className='content'>
                  <div className='rank-item'>
                    <span>{((balance.duration || 0) / 60).toFixed(1)}</span>
                    <em>总学时</em>
                  </div>
                  {/* <div className='rank-item'>
                    <span>{ranking.duration || '-'}</span>
                    <em>学时排名</em>
                  </div> */}
                  <div className='rank-item'>
                    <span>{(balance.credit || 0) / 1000}</span>
                    <em>已获学分</em>
                  </div>
                  <div className='rank-item'>
                    <span>{balance.sh || 0}</span>
                    <em>已获学时</em>
                  </div>
                </div>
              </div>
            </section>
            <section className='rank-box'>
              <div className='rank_content_wrap' onClick={() => browserHistory.push('/xp')}>
                <div className='rank_header'>
                  <span>我的积分</span>
                  <img src={small_black_arrow} className='rank_arrow' alt='' />
                </div>
                <div className='content'>
                  <div className='rank-item'>
                    <span>{(balance.xp || 0) / 1000}</span>
                    <em>已获积分</em>
                  </div>
                  <div className='rank-item'>
                    <span>{ranking.xp || '-'}</span>
                    <em>积分排名</em>
                  </div>
                  <div className='rank-item'>
                    <span>{continuePunchDay || 0}</span>
                    <em>连续签到</em>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>

        <section className='rank-box'>
          <List className='not-line list_wrap'>
            <Item
              arrow='horizontal'
              className='item'
              onClick={() => {
                browserHistory.push('/task')
              }}
            >
              <img src={Study} alt='study' className='icon' />
              学习档案
            </Item>
            <Item
              arrow='horizontal'
              className='item'
              onClick={() => {
                browserHistory.push('/train')
              }}
            >
              <img src={Train} alt='study' className='icon' />
              培训档案
            </Item>
            <Item
              arrow='horizontal'
              className='item'
              onClick={() => {
                browserHistory.push('/collection')
              }}
            >
              <img src={Collection} alt='study' className='icon' />
              我的收藏
            </Item>
            {/* extra={<Badge text={messages} overflowCount={99} />} */}
            <Item
              arrow='horizontal'
              className='item'
              onClick={() => {
                browserHistory.push('/message')
              }}
            >
              <img src={MessageIcon} alt='study' className='icon' />
              我的消息
            </Item>
            <Item
              arrow='horizontal'
              className='item custom-line'
              onClick={() => {
                browserHistory.push('/feedback')
              }}
            >
              <img src={FeedBack} alt='study' className='icon' />
              意见反馈
            </Item>
          </List>
        </section>
        <TabBarComponent selectedTab={'My'} />
      </div>
    )
  }
}
