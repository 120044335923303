import React, { Component } from 'react';
import { TextareaItem, ImagePicker, Button, Toast } from 'antd-mobile';
import Apis from '../apis/index';

import '../styles/feedback.less';

const { UserApi } = Apis;

export default class ForgetPwPage extends Component {
    state = {
        files: [],
        description: '',
        loading: false,
    };

    componentDidMount() {
        document.title = '意见反馈';
    }

    onInputChange = v => {
        this.setState({
            description: v,
        });
    };

    onFileChange = (files, type, index) => {
        this.setState({
            files,
        });
    };

    // 提交
    handleSubmit = async () => {
        await new Promise(res => this.setState({ loading: true }, res));
        try {
            // 上传图片
            const imgs = await Promise.all(
                this.state.files.map(async f => {
                    const fd = new FormData();
                    fd.append('file', f.file, f.file.name);
                    const res = await UserApi.upload(fd);
                    return res.url;
                })
            );
            await UserApi.addFeedback({
                content: {
                    description: this.state.description,
                    imgs,
                },
            });

            Toast.success('提交成功');
            window.history.back();
        } catch (e) {
            await new Promise(res => this.setState({ loading: false }, res));
        }
    };

    render() {
        const { files } = this.state;

        return (
            <div className='feedback_wrap'>
                <TextareaItem
                    autoFocus
                    placeholder='写下您的问题和建议，我们将为您不断改进'
                    autoHeight
                    rows={4}
                    onChange={this.onInputChange}
                />
                <ImagePicker
                    files={files}
                    onChange={this.onFileChange}
                    selectable={!files.length}
                    multiple={true}
                />
                <span
                    className='add_img_helper'
                    style={{
                        visibility: files.length ? 'hidden' : 'unset',
                    }}
                >
                    点击添加图片
                </span>
                <Button
                    type='primary'
                    className='submit_btn'
                    disabled={!this.state.description}
                    onClick={this.handleSubmit}
                    loading={this.state.loading}
                >
                    提交
                </Button>
            </div>
        );
    }
}
