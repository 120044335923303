import React, { Component } from 'react'
import '../styles/myMessage.less'
import Apis from '@/apis/'
import { browserHistory } from 'react-router'
import EMPTY_PIC from '../images/empty_pic_message@2x.png'
// import { Icon, List } from 'antd-mobile';
import { formatTime } from '@/util/common'


const MessageApi = Apis.MessageApi

export default class MyMessage extends Component {
  state = {
    messageList: []
  }
  componentDidMount() {
    document.title = '我的消息'
    MessageApi.getMyMessageList().then((res) => {
      let messageList = res || []
      // res.forEach((item) => {
      //   if (item.unread) {
      //     messageList.push(item)
      //   }
      // })
      this.setState({
        messageList
      })
    })
  }
  clickItem(item) {
    // let msgId = item.id
    // //改变他的已读未读状态
    // MessageApi.changeMessageStatus(msgId).then((res) => {
    //   // 做什么处理等二期
    // })
    // if (item.type && item.type === 'studyplan') {
    //   browserHistory.push(`/train/detail/${item.id}`)
    // }
    browserHistory.push(`/messageDetail`)
  }
  render() {
    const { messageList } = this.state
    let item = messageList.length ? messageList[0] : {}
    item.messageCount = 0
    if (messageList.length) {
      messageList.forEach((list) => {
        if (list.unread) {
          item.messageCount++
        }
      })
    }
    return (
      <div className="my-message-page">
        <div className="have-data">
          <MessageItem item={item} onClick={(item) => this.clickItem(item)} />
        </div>
      </div>
    )
  }
}

class MessageItem extends Component {
  clickItem(item) {
    this.props.onClick && this.props.onClick(item)
  }
  render() {
    let { item } = this.props
    return (
      <div className="message-item" onClick={() => { this.clickItem(item) }} >
        <div className="left-img">
          {/* <img src="" alt=""/> */}
        </div>
        <div className="right-cont">
          <div className="text-part">
            <p className="title">系统通知</p>
            <p className="desc ellipsis">{item.text}</p>
          </div>
          < div className="time">
            {
              item.receivedAt ?
                <span>{formatTime(item.receivedAt)}</span>
                : null
            }
            {
              item.messageCount ?
                <em>{item.messageCount}</em>
                : null
            }
          </div>
        </div>
      </div >
    )
  }
}

class NoData extends Component {
  render() {
    return (
      <div className="no-data">
        <img src={EMPTY_PIC} alt="#" />
        <p>暂无消息</p>
      </div>
    )
  }
}
