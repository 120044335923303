import React from 'react'
import { Tabs, Toast } from 'antd-mobile'
import '../styles/ReportPage.less'
import small_black_arrow from '../images/arrow_small@2x.png'
import ReactEcharts from 'echarts-for-react'
import Apis, { fetchCourseInfo, fetchCourseNode, fetchUserBalance } from '@/apis/'
import moment from 'moment'
import { fetchRankings } from '../apis/index'
import CourseItem from '@/components/CourseItem1'
import { get } from '../services/storage'
import CONFIG from '../config'

const UserApi = Apis.UserApi
const CourseApi = Apis.CourseApi

const tabs = [
  { title: <div className='nav_wrap'>学分概况</div> },
  { title: <div className='nav_wrap'>学时概况</div> },
]

const default_chart_opt = {
  xAxis: {
    type: 'category',
    axisTick: { show: false },
    data: [],
  },
  yAxis: {
    show: false,
    axisPointer: {
      snap: true,
    },
  },
  dataZoom: [
    {
      type: 'inside',
      startValue: 0,
      endValue: 0,
    },
  ],
  series: [
    {
      type: 'bar',
      data: [],
      itemStyle: {
        color: '#549EF8',
      },
      barWidth: 12,
      label: {
        normal: {
          show: true,
          position: 'top',
          distance: 15,
          align: 'center',
          verticalAlign: 'center',
          formatter: '{c}分',
          fontSize: 16,
          rich: {
            name: {
              textBorderColor: '#fff',
            },
          },
        },
      },
    },
  ],
}

class ReportPage extends React.Component {
  state = {
    credit_option: {
      ...default_chart_opt,
      tooltip: {
        trigger: 'axis',
        showContent: false,
        axisPointer: {
          type: 'shadow',
          snap: true,
          label: {
            formatter: ({ value, ...res }) => this.setState({ curDuration: value }),
          },
        },
      },
    },
    duration_option: {
      ...default_chart_opt,
      series: [
        {
          ...default_chart_opt.series[0],
          type: 'line',
          smooth: true,
        },
      ],
    },
    durations: {},
    curDuration: 0,

    courseList: [],
    currentIndex: '',
    durationlist: [],

    rank: {},
    balance: {},
  }

  async componentDidMount() {
    document.title = '学习报告'
    const [durationlist, credit_opt, duration_opt] = await Promise.all([
      this.getCredit('duration'),
      this.getChartData('credit'),
      this.getChartData('duration'),
      this.getPlan(),
      this.fetchRank('duration'),
      this.fetchUserBalance('duration'),
      this.fetchUserBalance('credit'),
    ])

    this.setState({
      durationlist,
      credit_option: credit_opt.option,
      durations: credit_opt.durations,
      curDuration: credit_opt.curDuration,
      duration_option: duration_opt.option,
    })
  }

  fetchUserBalance = async account => {
    const userInfo = JSON.parse(get(CONFIG.userInfo))
    const { data } = await fetchUserBalance(userInfo.id, account)
    this.setState({
      balance: {
        ...this.state.balance,
        [account]: data.balance,
      },
    })
  }

  fetchRank = async account => {
    const userInfo = JSON.parse(get(CONFIG.userInfo))
    const { data } = await fetchRankings(account)
    const rank = data.findIndex(_ => _.user_id === userInfo.id) + 1
    this.setState({
      rank: {
        ...this.state.rank,
        [account]: rank,
      },
    })
  }

  getCredit = async type => {
    let res = []
    if (type === 'duration') {
      res = await UserApi.getCredits(type, {
        s: moment().subtract(7, 'days').unix() * 1000,
      })
      // 补充7天数据
      for (let i = 0; i < 7; i++) {
        res.push({
          id: i * Math.random(),
          account: type,
          user_id: '',
          amount: 0,
          trade_at: moment().subtract(i, 'days').unix() * 1000,
          remark: null,
          org_id: null,
          foreign_id: null,
          flags: null,
        })
      }
    } else {
      res = await UserApi.getCredits(type)
      res.forEach(_ => (_.amount = (_.amount || 0) / 1000))
    }
    return res.map(_ => ({ ..._, trade_at: moment(Number(_.trade_at)) }))
  }

  // 获取 学分
  getChartData = async type => {
    const res = await this.getCredit(type)
    const durations = res.reduce((obj, item) => {
      const day = item.trade_at.format('YYYY.MM.DD')
      if (!obj[day]) {
        obj[day] = {
          data: [],
          trade_at: item.trade_at,
        }
      }
      obj[day].data.push(item)
      return obj
    }, {})

    const xAxisData = Object.keys(durations)
      .map(k => ({
        value: k,
        data: durations[k],
      }))
      .sort((a, b) => a.data.trade_at.format('YYYYMM.DD') - b.data.trade_at.format('YYYYMM.DD'))

    // const xAxisData = dks.map(k => durations[k].trade_at.format('MM.DD'));
    const barData = xAxisData.map(({ value }) => {
      let count = durations[value].data.reduce((count, item) => {
        count += item.amount
        return count
      }, 0)
      if (type === 'duration') {
        count = Number.parseInt(count)
      }
      return count
    })

    return {
      option: {
        ...this.state[`${type}_option`],
        xAxis: {
          ...this.state[`${type}_option`].xAxis,
          data: xAxisData,
        },
        series: [
          {
            ...this.state[`${type}_option`].series[0],
            data: barData,
          },
        ],
        dataZoom: [
          {
            ...this.state[`${type}_option`].dataZoom[0],
            startValue: xAxisData.length - 6,
            endValue: xAxisData.length - 1,
          },
        ],
      },
      durations,
      curDuration: xAxisData[xAxisData.length - 1]?.value,
    }
  }

  creditChartClickHandler = ({ name }) => {
    this.setState({ curDuration: name })
  }

  getPlan = async () => {
    const res = await CourseApi.getPlanList()
    res.length > 2 && (res.length = 2)

    for (let track of res) {
      try {
        track.course = (await fetchCourseInfo(track.courseId)).data
        track.paragraphCount = await fetchCourseNode(track.courseId)
      } finally {
        track.pro = track.paragraphCount
          ? Number.parseInt((track.progress.length / track.paragraphCount) * 100)
          : 0
      }
    }

    let courseList = res.map(item => {
      if (item?.pro === 100) {
        item.progress.status = 'complete'
      } else {
        item.progress.status = 'studying'
      }
      return item
    })
    this.setState({
      courseList,
    })
  }

  handleCurIndex(index) {
    this.setState({
      currentIndex: index,
    })
  }

  onCancelPlan(item) {
    let id = item.id
    CourseApi.delePlan(id)
      .then(res => {
        Toast.info('取消学习计划成功', 2, null, false)
        let { courseList } = this.state
        let resData = []
        courseList.forEach(itemData => {
          if (itemData.id !== id) {
            resData.push(itemData)
          }
        })
        this.setState({
          courseList: resData,
        })
      })
      .catch(err => {
        Toast.info('取消学习计划失败', 2, null, false)
      })
  }

  clickItem(item) {
    let courseId = item.courseId
    this.props.router.push(`/course/detail/${courseId}`)
  }

  tabClickHander = async index => {
    await this.getChartData(index ? 'duration' : 'credit')
  }

  formatDuration = (mins = 0) => (mins / 60).toFixed(1)

  render() {
    const { durations, curDuration, courseList, currentIndex, rank, balance } = this.state
    const curDurationList = durations[curDuration]

    let listView = courseList.map((item, index) => {
      return (
        <CourseItem
          canSlide={true}
          key={index}
          currentIndex={currentIndex}
          onSetCurIndex={index => this.handleCurIndex(index)}
          item={{
            ...item,
            date: item.createAt,
            showProgress: true,
            percent: item.progress.percent,
            cancelText: '计划',
          }}
          onCancel={item => {
            this.onCancelPlan(item)
          }}
          onClick={item => this.clickItem(item)}
        />
      )
    })

    const today_duration = this.formatDuration(
      this.state.durationlist
        .filter(_ => _.trade_at.format('YYYYMMDD') === moment().format('YYYYMMDD'))
        .reduce((count, _) => {
          count += _.amount
          return count
        }, 0)
    )

    let continuous_day = 0
    const getDay = time => {
      return Number.parseInt(time / 1000 / 60 / 60 / 24)
    }
    const today = getDay(Date.now())
    this.state.durationlist.every(_ => {
      if (getDay(_.trade_at._i) + continuous_day === today) {
        continuous_day++
        return true
      }
      if (getDay(_.trade_at._i) + continuous_day < today) {
        return false // 终止循环
      }
    })

    return (
      <div className='report_wrap'>
        <Tabs
          tabs={tabs}
          renderTabBar={props => (
            <div className='tabs_wrap'>
              <Tabs.DefaultTabBar {...props} />
            </div>
          )}
        >
          {/* 学分概况 */}
          <div className='credit_brief_wrap'>
            <header>
              <span>
                累计学分:
                <em>
                  {Object.values(durations).reduce((count, ds) => {
                    count += ds.data.reduce((c, d) => {
                      c += d.amount
                      return c
                    }, 0)
                    return count
                  }, 0)}
                </em>
              </span>
              <span>
                学时排名:
                <em>{rank.duration || '-'}</em>
              </span>

              <div>
                查看规则
                <img src={small_black_arrow} alt='' />
              </div>
            </header>

            <div className='chart'>
              <ReactEcharts
                onEvents={{
                  click: this.creditChartClickHandler,
                }}
                style={{ height: '200px', width: '100%' }}
                option={this.state.credit_option}
              />
            </div>

            <footer>
              <div className='footer_title_wrap'>
                <span className='footer_title_text'>
                  <span className='icon_blue'></span>
                  当日学分:
                  <em>
                    {!curDurationList
                      ? 0
                      : curDurationList.data.reduce((count, _) => {
                          count += _.amount
                          return count
                        }, 0)}
                  </em>
                </span>

                <span className='date'>
                  {curDurationList && moment(curDurationList.trade_at).format('YYYY/MM/DD')}
                </span>
              </div>

              <section className='list_wrap'>
                {curDurationList &&
                  curDurationList.data
                    .filter(_ => !!_.amount)
                    .map((_, i) => (
                      <div key={i}>
                        <span className='item_left'>
                          <span>{_.trade_at.format('HH:mm')}</span>
                          <em>{_.remark || '暂无'}</em>
                        </span>
                        <span className='item_right'>+{_.amount}分</span>
                      </div>
                    ))}
              </section>
            </footer>
          </div>
          {/* 学时概况 */}
          <div className='duration_brief_wrap'>
            <header>
              <span>
                最近7天学习时长:<em>(单位: 分钟)</em>
              </span>

              <div>
                查看规则
                <img src={small_black_arrow} alt='' />
              </div>
            </header>

            <div className='chart'>
              <ReactEcharts
                style={{ height: '200px', width: '100%' }}
                option={this.state.duration_option}
              />
            </div>

            <div className='footer_title_wrap'>
              <span className='footer_title_text'>
                <span className='icon_blue'></span>
                我的学习报告
              </span>
            </div>
            <div className='breif_wrap'>
              <section>
                <span>
                  {today_duration}
                  <span>时</span>
                </span>
                <em>今日学时</em>
              </section>
              <section>
                <span>{rank.duration || '-'}</span>
                <em>学时排名</em>
              </section>
              <section>
                <span>
                  {continuous_day}
                  <span>天</span>
                </span>
                <em>连续学习</em>
              </section>
              <section>
                <span>
                  {this.formatDuration(balance.duration)}
                  <span>时</span>
                </span>
                <em>累计学时</em>
              </section>
            </div>

            <div className='recent_wrap'>
              <div className='title_wrap'>
                <span className='title_text'>
                  <span className='icon_blue'></span>
                  最近学习
                </span>

                <div onClick={() => this.props.router.push(`/task`)}>
                  更多
                  <img src={small_black_arrow} alt='' />
                </div>
              </div>

              {listView}
            </div>
          </div>
        </Tabs>
      </div>
    )
  }
}

export default ReportPage
