import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import AdverImage from '../components/AdverImage';
import Apis from '../apis/index';
// import { applyToken } from '../util/wxconfig'
// import { Toast } from 'antd-mobile'

const AuthApi = Apis.AuthApi;

export default class Adver extends Component {
    componentDidMount() {
        let openId = this.getQueryString('openid') || '';
        let wxToken = this.getQueryString('wx_token') || '';
        console.log('openId' + openId);
        console.log('wxToken' + wxToken);
        if (openId) {
            // applyToken(openId, wxToken)
            localStorage.setItem('openId', openId);
            AuthApi.wxLogin({
                openid: openId,
            })
                .then(res => {
                    let userInfo = res ? JSON.stringify(res) : '';
                    // sessionStorage.setItem('userInfo', userInfo);
                    localStorage.setItem('userInfo', userInfo);
                    browserHistory.push('/');
                })
                .catch(err => {
                    browserHistory.push('/login');
                });
        } else {
            browserHistory.push('/login');
        }
    }
    getQueryString(name) {
        var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
        let href = window.location.href;
        let index = href.indexOf('?');
        if (index > -1) {
            let search = href.slice(index);
            var r = search.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
        }
        return null;
    }
    render() {
        return <AdverImage />;
    }
}
