import React, { Component } from 'react'
import '../styles/courseItem1.less'
import { formatDate } from '@/util/common'
import { Progress } from 'antd-mobile'

import IMG_TMP from '../images/tmp2.png'
import STATUS_COMPLETED from '../images/liebiao_pic_yiwanc@2x.png'
// import STATUS_UNCOMPLETED from '../images/liebiao_pic_weiwanc@2x.png'
import STATUS_STUDYING from '../images/liebiao_pic_xuexizhong@2x.png'
import STATUS_EXPIRED from '../images/liebiao_pic_yiguoqi@2x.png'

import { fetchCourseNode } from '../apis/index'

export default class CourseItem extends Component {
  static defaultProps = {
    item: {
      //临时的显示数据
      title: '电动机停机故障定位三步法',
      cover: IMG_TMP,
      rate: 4.9,
      company: '攀钢钒钛股份',
      date: 1564735807270,
      duration: 30,
      status: '',
      percent: 0,
      cancelText: '收藏',
      isTrain: false,
      showProgress: false, //展示完成了多少
      showEnd: false, //默认为展示开始时间，只有培训时展示截止时间
    },
    style: {},
    index: '',
    currentIndex: '',
    canSlide: false,
    clickItem: () => {},
    onSetCurIndex: () => {},
    paragraphCount: 0,
  }
  constructor(props) {
    super(props)
    this.state = {
      hasTransition: false,
    }
  }

  async componentWillReceiveProps(props) {
    await this.fetchCourseParagraphsCount(props.item.courseId)
  }

  touchStart(e) {
    this.startX = e.touches[0].pageX
    this.startY = e.touches[0].pageY
  }
  touchMove(e, index) {
    this.currentX = e.touches[0].pageX
    this.moveX = this.currentX - this.startX
    this.moveY = e.touches[0].pageY - this.startY
    //纵向移动则返回
    if (Math.abs(this.moveY) > Math.abs(this.moveX)) {
      return
    }
    //滑动超过一定距离时，才触发
    if (Math.abs(this.moveX) < 10) {
      return
    } else {
      this.setState({
        hasTransition: true,
      })
    }
    this.props.onSetCurIndex(index)
  }
  touchEnd(e) {
    this.setState({
      hasTransition: true,
    })
  }
  async componentDidMount() {
    this.startX = 0
    this.startY = 0
    this.currentX = 0
    this.moveX = 0
    this.moveY = 0
    await this.fetchCourseParagraphsCount(this.props.item.courseId)
  }

  fetchCourseParagraphsCount = async courseId => {
    const count = await fetchCourseNode(courseId)
    this.setState({
      paragraphCount: count,
    })
  }

  cancelCollect(e, item) {
    e.stopPropagation()
    this.setState({
      hasTransition: true,
    })
    this.moveX = 0
    this.props.onCancel && this.props.onCancel(item)
  }
  dealStatus(status) {
    // let res = ''
    switch (status) {
      case 'complete':
        return STATUS_COMPLETED
      case 'studying':
        return STATUS_STUDYING
      case 'expired':
        return STATUS_EXPIRED
      default:
        return null
    }
  }
  clickItem() {
    const { item } = this.props
    if (!item.progress) {
      item.progress = {}
    }
    localStorage.setItem('courseItem', JSON.stringify(item))
    this.props.onClick && this.props.onClick(item)
  }

  render() {
    const { item, index, currentIndex, canSlide, style } = this.props
    // console.log({ item })
    const { hasTransition, paragraphCount } = this.state
    // let status = this.dealStatus(item.status)
    // 左滑时，出现del，右滑时，恢复原位，距离为操作按钮大小
    // 也可以将滑动距离作为移动距离，但是效果不太好
    const distance = this.moveX >= 0 ? 0 : -0.6
    let moveStyle = {}
    if (canSlide && hasTransition && currentIndex === index) {
      moveStyle.transform = `translateX(${distance}rem)`
      moveStyle.WebkitTransform = `translateX(${distance}rem)`
      moveStyle.transition = 'transform 0.3s ease'
      moveStyle.WebkitTransition = 'transform 0.3s ease'
    }

    return (
      <div
        className='course-item-wraper'
        style={style.wraperStyle}
        onClick={() => this.clickItem()}
      >
        {/* backgroundImage: `url(${status})`  */}
        <div
          className='course-item1'
          style={{ ...moveStyle }}
          onTouchStart={e => this.touchStart(e)}
          onTouchMove={e => this.touchMove(e, index)}
          onTouchEnd={e => this.touchEnd(e)}
        >
          <div className='left'>
            <img src={item?.course?.cover} alt='cover' />
          </div>
          <div className='right'>
            <p className='title ellipsis'>{item?.course?.title}</p>
            {item.isTrain ? (
              <>
                <p className='date'>
                  <em>
                    {item.showEnd ? '截止：' : null}
                    {formatDate(item.date)}
                  </em>
                  {item.showProgress ? (
                    <em style={{ marginLeft: '8px' }}>
                      已学习：[{item.progress.length || 0} / {paragraphCount}]
                    </em>
                  ) : null}
                </p>
                <div className='info'>
                  <span>学分：{item.credit}</span>
                </div>
              </>
            ) : (
              <>
                <p className='percent'>
                  已学习：[{item.progress.length || 0} / {paragraphCount}]
                </p>
                <Progress
                  className={`progress ${item.pro === 100 && 'complete'}`}
                  position='normal'
                  percent={item.pro}
                  appearTransition
                />
                <p className='last_date'>上次学习 : {formatDate(item.updateAt)}</p>
              </>
            )}
          </div>
          <div className='cancel-collect' onClick={e => this.cancelCollect(e, item)}>
            <em>取消</em>
            <em>{item.cancelText}</em>
          </div>
        </div>
      </div>
    )
  }
}
