import React, { Component } from 'react'
import '../styles/rankItem.less'

// import STATUS_COMPLETED from '../images/liebiao_pic_yiwanc@2x.png'
// import STATUS_UNCOMPLETED from '../images/liebiao_pic_weiwanc@2x.png'
// import STATUS_EXPIRED from '../images/liebiao_pic_yiguoqi@2x.png'


export default class RankItem extends Component {
  render() {
    const { item, index, label, formatter = v => v } = this.props
    return (
      <div className='rank-item-box'>
        <p className={`order order-rank${index + 1}`}  >{index + 1 > 3 ? `0${index + 1}` : ''}</p>
        <img src={item.avatar} alt="#" className="avatar" />
        <div className="cont">
          <div className="info">
            <p className="name">{item.name}</p>
            <p className="score">当前{label}：{formatter(item.value)}</p>
          </div>
        </div>
      </div >
    )
  }
}