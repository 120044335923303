import { axiosget } from '../config/axios'


//获取培训计划（列表）
export function getTrainPlan(data) {
  // return axiosget(`/courseinterface/v1/training_plans`, data)
  return axiosget(`/traineeinterface/v1/studyplans`, data)
}
//获取培训计划(详情)
export function getTrainDetail(planId, data) {
  return axiosget(`/traineeinterface/v1/studyplans/${planId}`, data)
}


export default {
  getTrainPlan,
  getTrainDetail
}