import React, { Component } from 'react'
import CourseItem from '@/components/CourseItem'
import { formatDate } from '@/util/common'


import '@/styles/trainDetail.less'
// import IMG_COVER from '../images/tmp2.png'
import Apis from '@/apis/'
const TrainApi = Apis.TrainApi
// const MessageApi = Apis.MessageApi


export default class TestCenterPage extends Component {
  state = {
    value: '',
    detail: {
      publisher: {}
    }
  }
  componentDidMount() {
    document.title = '培训详情'
    let id = this.props.params.id
    if (!id) {
      this.props.router.push('/train')
    }
    // //改变他的已读未读状态
    // MessageApi.changeMessageStatus(id).then((res) => {
    //   // 做什么处理等二期
    //   console.log('success')
    // })
    TrainApi.getTrainDetail(id).then((res) => {
      this.setState({
        detail: { ...this.state.detail, ...res }
      })
    })
  }
  clickItem(item) {
    //在这里是从详情里面点击进去，给他添加唯一的表示，isFromTrian
    item.isFromTrain = true
    item.zhaodashenId = this.state.detail.id
    localStorage.setItem('courseItem', JSON.stringify(item))
    let courseId = item.courseId
    this.props.router.push(`/course/detail/${courseId}`)
  }
  render() {
    const { detail } = this.state
    let courseListView = null
    if (detail.courseStudyPlans && detail.courseStudyPlans.length) {
      courseListView = detail.courseStudyPlans.map((item, index) => {
        // myAuthor: item.author.name, myPublisher: item.publisher.name
        let percent = item.progress ? item.progress.percent : 0
        let status = ''
        if (percent >= 0 && percent < 100) {
          status = 'studying'
        } else if (percent === 100) {
          status = 'complete'
        } else {
          status = 'unStart'
        }
        return <CourseItem
          key={item.courseId}
          onClick={(item) => this.clickItem(item)}
          item={{
            ...item,
            date: item.startAt,
            title: item.title, myAuthor: item.author ? item.author.name : '',
            myPublisher: item.publisher ? item.publisher.name : '',
            status: status
          }} />
      })
    }
    return (
      <div className="train-detail-page" >
        <div className="cover-img">
          <img src={detail.cover} alt="#" />
        </div>
        <div className="cont-box">
          <h3>{detail.name}</h3>
          <div className="date">{formatDate(detail.startAt)}～{formatDate(detail.endAt)}</div>
          <p style={{ marginTop: '10px' }}>发布组织：{detail.orgName}</p>
          <p style={{ marginTop: '10px' }}>发布时间：{detail.publishedAt ? formatDate(detail.publishedAt) : null}</p>
          {/* <div className="course-info">学时：<em>{detail.duration}</em><span className="line"></span>学分：<em>{detail.credit}</em></div> */}
          <div className="info-title">培训简介：</div>
          <p>{detail.description}</p>
          <div className="info-title">适用人群：</div>
          <p>{detail.targetTrainees}</p>
          <div className="info-title">培训课程：</div>
        </div>
        <div className="course-box top-line">
          {courseListView}
        </div>
      </div>
    )
  }
}