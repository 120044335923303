import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import AdverImage from '../components/AdverImage'
import Apis from '../apis/index'
// import { applyToken } from '../util/wxconfig'
// import { Toast } from 'antd-mobile'

const AuthApi = Apis.AuthApi

export default class Adver extends Component {
  componentDidMount() {
    setTimeout(() => {
      // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx025f81fc89447cdc&redirect_uri=http%3A%2F%2Fkeyuan.xuanjiacd.com%2Fauthinterface%2Fv1%2Fwx&response_type=code&scope=snsapi_base&state=123#wechat_redirect'
      // browserHistory.push('/')
      AuthApi.authToken().then(() => {
        //每次刷新页面的时候且请求token验证完毕后
        browserHistory.push('/')
      }).catch((err) => {
        browserHistory.push('/login')
      })
    }, 3000)
  }
  render() {
    return (
      <AdverImage />
    )
  }
}