import React, { Component } from 'react'
import { SearchBar } from 'antd-mobile'
import '@/styles/searchBox.less'


export default class SearchBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ''
    }
  }
  onChange(value) {
    this.setState({
      value
    })
  }
  onCancel() {
    this.setState({
      value: ''
    })
    this.props.onCancel && this.props.onCancel('')
  }
  onSubmit() {
    this.props.onSubmit(this.state.value)
  }
  onFocus() {
    this.props.onFocus && this.props.onFocus()
  }
  render() {
    let { name, placeholder } = this.props
    return (
      <SearchBar
        value={this.state.value}
        className={`base-search-box ${name}`}
        placeholder={placeholder}
        onFocus={() => this.onFocus()}
        onChange={(e) => this.onChange(e)}
        onCancel={() => this.onCancel()}
        onSubmit={() => this.onSubmit()}
      />
    )
  }
}