import React, { Component } from 'react'
import CourseItem from '@/components/CourseItem'
import LoadFail from '@/components/LoadFail'
import Apis, {fetchCourseInfo} from '@/apis/'
import '@/styles/myCollection.less'
import NO_COLLECTION from '../images/empty_pic_collection@2x.png'
import { Toast } from 'antd-mobile'


const CourseApi = Apis.CourseApi

export default class MyCollection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      failShow: false,
      data: [],
      currentIndex: ''
    }
  }
  
  async componentDidMount() {
    document.title = '我的收藏'
    const courseIds = await CourseApi.getCollectedCourse()

    const courses = await Promise.all(
      courseIds.map(async id => {
        try {
          const { data } = await fetchCourseInfo(id)
          return data
        } catch (e) {}
      })
    )

    this.setState({
      data: courses.filter(_ => !!_),
    })
  }

  onCancelCollect(item) {
    let id = item.id
    CourseApi.collectCourse(id).then((res) => {
      Toast.info('取消收藏成功', 2, null, false)
      const { data } = this.state
      let resData = []
      data.forEach((dataItem) => {
        if (dataItem.id !== id) {
          resData.push(dataItem)
        }
      })
      this.setState({
        data: resData
      })
    })
  }

  handleCurIndex(index) {
    this.setState({
      currentIndex: index
    })
  }
  clickItem(item) {
    let id = item.id
    this.props.router.push(`/course/detail/${id}`)
  }
  render() {
    const { failShow, data, currentIndex } = this.state

    const myCollections = []
    if (data.length) {
      for (let index = 0; index < data.length; index++) {
        let item = data[index]
        myCollections.push(
          <CourseItem
            canSlide={true}
            item={{
              ...item,
              date: item.updateAt,
              cancelText: '收藏',
              myAuthor:  item.author?.d?.name || item.author?.name || '',
              myPublisher: item.publisher?.name || '',
            }}
            key={index}
            index={index}
            currentIndex={currentIndex}
            onSetCurIndex={index => this.handleCurIndex(index)}
            onClick={item => this.clickItem(item)}
            onCancel={item => {
              this.onCancelCollect(item)
            }}
          />
        )
      }
    }

    return (
      <div className='my-collect-page' >
        {failShow ? <LoadFail /> :
          <div className='no-fail'>
            {
              data.length ?
                <div className="have-data">
                  {myCollections}
                </div> :
                <div className="no-data">
                  <img src={NO_COLLECTION} alt="#" />
                  <p>空空如也，暂无相关收藏</p>
                </div>
            }
          </div>
        }
      </div>
    )
  }
}