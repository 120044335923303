import React from 'react';
import '../styles/testItem.less';
import moment from 'moment';

export default function TestItem({ title, begin, end, score }) {
  begin = moment(begin).format('YYYY-MM-DD');
  end = moment(end).format('YYYY-MM-DD');

  return (
    <div className='test-item'>
      <p className='title'>{title}</p>
      <p className='date'>
        {begin} 至 {end}
      </p>
      <p className='score'>{score || '-'} 分</p>
    </div>
  );
}
