import { axiosget, axiospatch } from '../config/axios'

//我的消息列表
export function getMyMessageList(data) {
  return axiosget(`/traineeinterface/v1/messages`, data)
}

//更改消息的状态
export function changeMessageStatus(msgId, data) {
  return axiospatch(`/traineeinterface/v1/messages/${msgId}`, data)
}


export default {
  getMyMessageList,
  changeMessageStatus
}
