import React, { Component } from 'react'
import { Link } from 'react-router'
import { InputItem, Button, Toast, ActivityIndicator } from 'antd-mobile'
import { browserHistory } from 'react-router'
import '../styles/login.less'
import Apis, { fetchTheme } from '../apis/index'
// import icon_name from '../images/icon_name.png'
// import icon_password from '../images/icon_password.png'
// import icon_logo from '../images/icon_logo.png'
import icon_logo from '../images/icon_logos.jpg'
import Socket from '../util/socket'
import { getOrgIdOnDomin, getUserInfo } from '@/util/common'
import { get } from '../services/storage'
import config from '../config'

const { AuthApi } = Apis

export default class LoginPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      password: '',
      rememberPwd: false,
      theme: {
        avatar: 'https://kyzx.oss-cn-beijing.aliyuncs.com/coreland.jpeg',
        backgroud:
          'http://coreland-online.oss-cn-chengdu.aliyuncs.com/3dd214f1a958484b87b34f10dd2a8df1.png',
        btn_bg_color: '#59A4FF',
        eventValidation:
          '/wEdAAMwV7QvAireBEBeGl5jkqdxY3plgk0YBAefRz3MyBlTcDPSlu16Yx4QbiDU+dddK1MfeVvR3jTtVmjdZoxozUIpYwOsJMOsDXEuCLyN/TzoUA==',
        exam: 'http://educenterexam.coreland.net/m/login.aspx',
        logo: icon_logo,
        reportInstanceName: 'exam-keyuan',
        title: '科园在线',
        viewState: '/wEPDwULLTE4NzExMjM1MTdkZD04Loa/rkCwd1XGR4l1bkNyXu4vEtu9AyUalOoXtTm1',
      },
      orgIdOnDomin: 0,
      loading: false,
    }
  }

  async componentDidMount() {
    this.setState({ loading: true })
    const orgIdOnDomin = getOrgIdOnDomin()
    try {
      document.title = '登录'
      if (getUserInfo()) {
        window.socket = new Socket()
        browserHistory.push('/')
      }
      if (window.socket) {
        window.socket.close()
      }
      this.setState({ orgIdOnDomin })
      await this.fetchTheme()
    } finally {
      this.setState({ loading: false })
    }
  }

  fetchTheme = async () => {
    const orgId = window[config.orgIdKey]
    if (!orgId) return
    const { data: theme } = await fetchTheme(orgId)
    document.title = theme.title
    this.setState({ theme: { ...this.state.theme, ...theme } })
  }

  submit() {
    if (!this.validatePhone() || !this.validatePass()) {
      return
    }
    let openId = localStorage.getItem('openId')
    AuthApi.login({
      username: this.state.phone,
      password: this.state.password,
      openid: openId,
      org_id: getOrgIdOnDomin(),
    }).then(res => {
      //登录成功连接socket
      window.socket = new Socket()
      let userInfo = res ? JSON.stringify(res) : ''
      // if (this.state.rememberPwd) {
      //     sessionStorage.setItem('userInfo', userInfo);
      // } else {
      //     sessionStorage.setItem('userInfo', userInfo);
      // }
      localStorage.setItem('userInfo', userInfo)
      browserHistory.push('/')
    })
  }

  changePhone(value) {
    this.setState({
      phone: value,
    })
  }

  changePass(value) {
    this.setState({
      password: value,
    })
  }

  validatePhone() {
    let value = this.state.phone.toString().trim()
    if (!value) {
      Toast.info('用户名必填', 3, null, false)
      return false
    }
    return true
  }

  validatePass() {
    let value = this.state.password.toString().trim()
    let reg = /^.{6,12}$/
    if (reg.test(value)) {
      return true
    }
    Toast.info('请输入6到12位密码', 3, null, false)
    return false
  }

  render() {
    const { loading, theme } = this.state

    if (loading) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
          }}
        >
          <ActivityIndicator text='正在加载' />
        </div>
      )
    }

    return (
      <div
        className='login-container'
        style={{
          backgroundImage: `url(${theme.backgroud})`,
          '--act_text_color': theme.act_text_color || '#dddfe6',
        }}
      >
        {theme.sub_logo && (
          <img
            src={theme.sub_logo}
            style={{
              position: 'absolute',
              top: '0.165rem',
              right: '0.22rem',
              width: '.68rem',
              height: '.54rem',
            }}
            alt=''
          />
        )}
        <div className='logo-box'>
          <img
            className='logo'
            src={theme.logo}
            alt='logo'
            style={{
              maxWidth: 120,
            }}
          />
        </div>
        <InputItem
          clear
          placeholder='请输入手机号'
          className='phone not-line input-wrap'
          value={this.state.phone}
          onChange={value => this.changePhone(value)}
          style={{
            color: theme.act_text_color,
            '--placeholder-color': theme.act_text_color || '#9389a5',
          }}
        >
          {/* <img src={icon_name} alt='' /> */}

          <svg
            width='22px'
            height='22px'
            viewBox='0 0 16 16'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>用户@2x</title>
            <g id='app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g
                id='画板'
                transform='translate(-127.000000, -19.000000)'
                fill={theme.act_text_color || '#cbcbcb'}
                fillRule='nonzero'
              >
                <g id='用户' transform='translate(128.000000, 19.000000)'>
                  <g transform='translate(0.000000, 0.500000)'>
                    <circle id='椭圆形' cx='7' cy='4.5' r='4'></circle>
                    <path
                      d='M10.69425,7.53333333 C9.74458333,8.59721725 8.43966667,9.2567857 7,9.2567857 C5.56033333,9.2567857 4.25541667,8.59721725 3.30575,7.53333333 C1.33641667,8.77332203 0,11.1490876 0,13.8737649 C4.720952e-17,14.2592599 0.271331376,14.5914965 0.649055771,14.6685073 C3.36777162,15.2228358 5.48475303,15.5 7,15.5 C8.51524697,15.5 10.6322284,15.2228358 13.3509442,14.6685073 C13.7286686,14.5914965 14,14.2592599 14,13.8737649 L14,13.8737649 L14,13.8737649 C14,11.1490876 12.6635833,8.77332203 10.69425,7.53333333 Z'
                      id='路径'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </InputItem>
        <InputItem
          clear
          type='password'
          placeholder='密码为123456'
          className='password not-line input-wrap'
          value={this.state.password}
          onChange={e => this.changePass(e)}
          style={{
            color: theme.act_text_color,
            '--placeholder-color': theme.act_text_color || '#9389a5',
          }}
        >
          {/* <img src={icon_password} alt='' /> */}
          <svg
            width='22px'
            height='22px'
            viewBox='0 0 16 16'
            version='1.1'
            xmlns='http://www.w3.org/2000/svg'
          >
            <title>密码@2x</title>
            <g id='app' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g
                id='画板'
                transform='translate(-107.000000, -19.000000)'
                fill={theme.act_text_color || '#cbcbcb'}
                fillRule='nonzero'
              >
                <g id='密码' transform='translate(108.000000, 19.500000)'>
                  <g id='lock' transform='translate(0.000000, 0.500000)'>
                    <path
                      d='M7.09515529,0 C5.05229815,0 3.38086958,1.6875 3.38086958,3.75 L3.38086958,4.6875 L1.52372672,4.6875 C0.966583864,4.6875 0.595155292,5.0625 0.595155292,5.625 L0.595155292,14.0625 C0.595155292,14.625 0.966583864,15 1.52372672,15 L12.6665839,15 C13.2237267,15 13.5951553,14.625 13.5951553,14.0625 L13.5951553,5.625 C13.5951553,5.0625 13.2237267,4.6875 12.6665839,4.6875 L10.809441,4.6875 L10.809441,3.75 C10.809441,1.6875 9.13801244,0 7.09515529,0 Z M8.02372672,9.99202898 L8.02372672,11.6654114 C8.02372672,12.1782472 7.60799113,12.5939828 7.09515529,12.5939828 C6.58231945,12.5939828 6.16658386,12.1782472 6.16658386,11.6654114 L6.16658386,9.99202898 L6.16658386,9.99202898 C5.60944101,9.71077898 5.23801244,9.05452898 5.23801244,8.39827898 C5.23801244,7.36702898 6.07372672,6.52327898 7.09515529,6.52327898 C8.11658386,6.52327898 8.95229815,7.36702898 8.95229815,8.39827898 C8.95229815,9.05452898 8.58086958,9.71077898 8.02372672,9.99202898 Z M8.95229815,4.6875 L5.23801244,4.6875 L5.23801244,3.75 C5.23801244,2.71875 6.07372672,1.875 7.09515529,1.875 C8.11658386,1.875 8.95229815,2.71875 8.95229815,3.75 L8.95229815,4.6875 Z'
                      id='形状'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </InputItem>
        <div className='password_wrap'>
          {/* <Checkbox
            value={this.state.rememberPwd}
            onChange={e =>
                this.setState({
                    rememberPwd: e.target.checked,
                })
            }
            className='remember'
        >
            记住密码
        </Checkbox> */}
          <i></i>
          <Link
            to='/forgetPw'
            className='forget'
            style={{
              color: theme.act_text_color,
            }}
          >
            忘记密码?
          </Link>
        </div>

        <div className='login_btn_wrap'>
          <Button
            onClick={() => this.submit()}
            className='login-btn'
            style={{
              backgroundColor: theme.btn_bg_color,
              color: theme.btn_text_color || 'white',
              width: theme.btn_width || '100%',
              height: theme.btn_height,
              lineHeight: theme.btn_height,
              fontSize: theme.btn_fontsize,
            }}
          >
            登录
          </Button>
        </div>
      </div>
    )
  }
}
