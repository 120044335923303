import io from 'socket.io-client';
import { getUserInfo } from '@/util/common';
const socketUrl = process.env.REACT_APP_SOCKET_URL;

export default class Socket {
    constructor() {
        let userInfo = getUserInfo();
        let token = userInfo ? userInfo.token : '';
        token = 'Bearer ' + token;
        this.socket = io(socketUrl, {
            path: '/socketinterface/v1/ws',
            transports: ['websocket'],
            query: {token}
        });
    }
    emit() {
        this.socket.emit('message', 'client message');
    }
    message() {
        this.socket.on('message', data => {
            console.log(data);
        });
    }
    connect() {
        this.socket.on('connect', data => {
            console.log('connect', data);
        });
    }
    disconnect() {
        this.socket.on('disconnect', data => {
            console.log('disconnect', data);
        });
    }
    close() {
        this.socket.disconnect();
        this.socket.close();
        this.socket.on('close', () => {
            console.log('close');
        });
    }
}
