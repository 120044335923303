import React, { Component } from 'react'
import UnDevelop from '../components/UnDevelop'



export default class RankItem extends Component {

  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <UnDevelop />
      </div >
    )
  }
}