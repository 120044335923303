import axios from 'axios';
// import { getCookie } from '../util/cookie'
import { Toast } from 'antd-mobile';
import { browserHistory } from 'react-router';
import { getUserInfo } from '@/util/common';

export const url = process.env.REACT_APP_API_URL;

let DoNotShowError = false
export const toggleShowError = show => (DoNotShowError = show)

const instance = axios.create({
    baseURL: url,
    headers: {
        'Content-Type': 'application/json',
    },
    timeout: 10000,
});

instance.interceptors.request.use(
    function(config) {
        let userInfo = getUserInfo();
        let token = userInfo ? userInfo.token : '';
        if (token) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        return config;
    },
    function(error) {
        // Do something with request error
        console.log(error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function(response) {
        // Do something with response data
        if (response.status !== 200 && !DoNotShowError) {
          Toast.fail(response.data, 2, null, false)
        }
        return response;
    },
    function(error) {
        // Do something with response error
        let cancelToastFlag = false;
        let whiteList = ['/authinterface/v1/verify'];
        for (let i = 0, l = whiteList.length; i < l; i++) {
            let item = whiteList[i];
            if (error.config && error.config.url.indexOf(item) > -1) {
                cancelToastFlag = true;
            }
        }
        console.log('error.response', error.response);
        if (error.response && error.response.status === 401) {
            if (!cancelToastFlag) {
                Toast.fail('未登录', 2, null, false);
            }
            localStorage.removeItem('userInfo');
            browserHistory.push('/login');
        } else {
            if (error.response && error.response.data && !DoNotShowError) {
                let message = typeof error.response.data === 'string' ? error.response.data : error.response.data.message;
                Toast.fail(message, 2, null, false);
            }
        }
        return Promise.reject(error);
    }
);

export default instance;
