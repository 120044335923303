import { axiospost, axiosget } from '../config/axios'

let prefix = '/authinterface/v1'

//登录
export function login(data) {
  return axiospost(prefix + '/login', data)
}
//验证token
export function authToken(data) {
  return axiosget(prefix + '/verify', data)
}

//微信登录
export function wxLogin(data) {
  return axiospost(`${prefix}/wx/login`, data)
}

//获取跳转
export function getWxOpenId(data) {
  return axiosget(`authonterface/v1/wx/redirect`, data)
}

export default {
  login,
  authToken,
  wxLogin,
  getWxOpenId
}