import React, { Component } from 'react';
import { Tabs, Toast, Icon, ActivityIndicator } from 'antd-mobile';
import Apis, {fetchCourseDetail} from '@/apis/';
import { updateCourseTrack } from '../apis/course'
import MyVideo from '../components/MyVideo1';
import '../styles/courseDetailPage.less';
import { wxConfig, wxGetNetworkType } from '../util/wxconfig';
import { formatDate } from '@/util/common';
// import tmpImg from '../images/tmp2.png';
import { browserHistory } from 'react-router';
import { set, get } from 'lodash'
import { updateDuration } from '../apis'
const CourseApi = Apis.CourseApi;
// const AuthApi = Apis.AuthApi

const tabs = [
  { title: '介绍' },
  { title: '目录' },
  // { title: '评论' }
];
const CHAPTER_STATUS = {
  complete: '已完成',
  unStart: '未开始',
  studying: '学习中',
};

const fetchCourseTrack = async (courseId) => {
  const tracks = await CourseApi.getPlanList();
  const track = tracks.find(t => t.courseId === courseId)
  if (track) {
    return track
  } else {
    return await CourseApi.joinPlan({ courseId })
  }
}

export default class CourseDetailPage extends Component {
  constructor(props) {
    super(props);
    this.allChapters = 0; //视频的章节树
    this.addPlayClicks = 0;
    this.timer = null
    this.state = {
      detail: {
        publisher: {},
      },
      collected: false,
      current: {},
      next: {},
      chapters: [],
      showValuate: false,
      valuated: false,
      autoPlay: false,
      isLast: false,
      networkType: window.networkType,
      loading: true,
      favorites: []
    };
  }

  componentDidMount() {
    document.title = '课程详情';
    if (!this.state.networkType) {
      wxConfig();
      wxGetNetworkType().then(res => {
        this.setState({
          networkType: res,
        });
      });
    }

    const courseItem = this.getCourseItem();
    let isFromTrain = !!courseItem.isFromTrain; //用于判断是否是从培训页面点进来的课程
    this.isFromTrain = isFromTrain;
    const courseId = this.props.params.id;
    //当id不存在时的跳转
    if (!courseId) {
      if (isFromTrain) {
        this.props.router.push('/train');
      } else {
        this.props.router.push('/task');
      }
    }
    this.courseId = Number(courseId);
    this.fetchFavorites() 

    CourseApi.getCourseInfo(courseId).then(async res => {
      const chapters = await fetchCourseDetail(courseId)
      chapters.forEach(c => {
        c.name = c.title
        c.paragraphs.forEach(p => {
          p.name = p.title
          p[p?.content?.type] = p.content
          Object.keys(p.content?.extraData || {}).forEach(k => {
            set(p, [p?.content?.type, k], p.content?.extraData[k])
          })
        })
      })
      this.courseItem = { progress: courseItem.progress, ...res };
      if (!get(this, ['courseItem', 'progress', 'completeParagraphs'])) {
        set(this, ['courseItem', 'progress', 'completeParagraphs'], [])
      }
      if (!this.courseItem.progress.studyArr) {
        this.courseItem.progress.studyArr = [];
      }
      // if (isFromTrain) { //当是培训计划时，进度接口的两个id由外面传进来
      //   this.courseItem = { ...this.courseItem, zhaodashenId: courseItem.zhaodashenId, courseId: courseItem.courseId }
      // }
      //给每一章节添加学习状态
      let result = await this.dealChapterStatus(
        chapters,
        this.courseItem,
        this.courseItem.progress.currentId
      );
      //判断是否已点评
      let valuated = false;
      if (res.userRate && res.userRate > 0) {
        valuated = true;
      } else {
        valuated = false;
      }
      const track = await fetchCourseTrack(this.courseItem.id)
      this.setState({
        detail: {
          ...this.courseItem,
          ...track,
          updateAt: this.courseItem.updateAt,
          tracked: !!Object.keys(track.userDefinedTrack || {}).length,
        },
        collected: !!res.favorite,
        current: result.current,
        chapters: result.chapters,
        valuated: valuated,
        isLast: result.isLast,
        loading: false,
      })
      // this.changePlayed() //为点击播放时不请求接口
    });
  }

  async componentWillUnmount() {
    await this.endTimer()
  }

  changePlayed() {
    this.addPlayClicks++;
    if (this.addPlayClicks <= this.allChapters) {
      CourseApi.changePlayed(this.courseId).then(res => {});
    }
  }

  fetchFavorites = async () => {
    const favorites = await CourseApi.getCollectedCourse()
    this.setState({
      favorites
    })
  }

  async dealChapterStatus(chapters = [], courseItem, currentId) {
    const track = await fetchCourseTrack(this.courseItem.id)
    //courseItem为从列表存储进loacl的课程相的数据
    let res = {
      chapters: [],
      current: {},
      next: null,
      isLast: false,
      allComplete: false,
    };
    let completeParagraphs = track?.progress || [];
    let studyArr = courseItem.progress.studyArr || [];
    //不管有没有当前视频播放，则默认第一章为当前视频
    if (chapters?.[0]?.paragraphs?.length) {
      res.current = chapters[0].paragraphs[0]
      let id = chapters[0].paragraphs[0].id
      if (!studyArr.includes(id)) {
        studyArr.push(id)
      }
      if (chapters[0].paragraphs[1]) {
        res.next = chapters[0].paragraphs[1]
      }
    }
    let allChapters = 0;
    let foundStudying = false
    res.chapters = chapters.map((item, index) => {
      if (item.paragraphs && item.paragraphs.length) {
        allChapters = allChapters + item.paragraphs.length;
        item.paragraphs.forEach((innerItem, innerIndex) => {
          if (completeParagraphs.includes(innerItem.id)) {
            innerItem.status = 'complete';
          } else if (!foundStudying) {
            foundStudying = true
            innerItem.status = 'studying';
            res.current = innerItem
          } else {
            innerItem.status = 'unStart';
          }
          if (currentId && currentId === innerItem.id) {
            res.current = innerItem;
            // 如果是最后一章
            if (
              innerIndex === item.paragraphs.length - 1 &&
              index === chapters.length - 1
            ) {
              res.next = null;
              res.isLast = true;
            } else {
              res.isLast = false;
              if (innerIndex !== item.paragraphs.length - 1) {
                res.next = item.paragraphs[innerIndex + 1];
              } else {
                res.next = chapters[index + 1].paragraphs[0];
              }
            }
          }
        });
      }
      return item;
    });
    if (allChapters === 1) {
      res.isLast = true;
    }
    this.allChapters = allChapters;
    if (completeParagraphs.length === this.allChapters) {
      res.allComplete = true;
    }
    return res;
  }

  async clickChapterItem(item) {
    if (!item || !item.id) {
      return;
    }
    let completeParagraphs = this.courseItem.progress.completeParagraphs || [];
    let studyArr = this.courseItem.progress.studyArr || [];
    let currentId = item.id;
    //不在已经完成的数组中时，将其状态改变为学习中，在则不改变其状态
    let index = completeParagraphs.indexOf(currentId);
    if (index === -1) {
      if (!studyArr.includes(currentId)) {
        studyArr.push(currentId);
      }
    }
    //改变localStorge里progress的值，同时上传接口，
    this.courseItem.progress.completeParagraphs = completeParagraphs;
    this.courseItem.progress.studyArr = studyArr;
    this.courseItem.progress.currentId = currentId;
    //改变chapter，
    let result = await this.dealChapterStatus(
      this.state.chapters,
      this.courseItem,
      currentId
    );
    this.setState({
      chapters: result.chapters,
      current: item,
      autoPlay: true,
      isLast: result.isLast,
    });
    // this.changeProgress(item, currentId);
    // this.changePlayed()
  }
  changeProgress = async (item, currentId, flag) => {
    if (!this.state.detail.tracked) return

    let trackId = this.state.detail.id

    const paragraphId = this.state.current.id

    try {
      await CourseApi.changePlanCourseProgress(trackId, paragraphId)
      localStorage.setItem('courseItem', JSON.stringify(this.courseItem))
    } catch (err) {
      // 失败则吧this.courseItem还原成local里原来的值，直接从local里取，因为未成功，没有改变其值
      this.courseItem = this.getCourseItem()
    }
    localStorage.setItem('courseItem', JSON.stringify(this.courseItem))
  };
  onFinish = async current => {

    const {detail} = this.state
    
    //视频播放完成后进行操作，
    let completeParagraphs = this.courseItem.progress.completeParagraphs || [];
    let studyArr = this.courseItem.progress.studyArr || [];
    let currentId = current.id;
    if (!completeParagraphs.includes(currentId)) {
      completeParagraphs.push(currentId);
    }
    let index = studyArr.indexOf(currentId);
    if (index > -1) {
      studyArr.splice(index, 1);
    }
    //改变localStorge里progress的值，同时上传接口，
    this.courseItem.progress.completeParagraphs = completeParagraphs;
    this.courseItem.progress.studyArr = studyArr;
    this.courseItem.progress.currentId = currentId;
    let result = await this.dealChapterStatus(
      this.state.chapters,
      this.courseItem,
      currentId
    );
    if (this.courseItem.progress.percent !== 100 && result.allComplete) {
      Toast.info('课程学习完毕', 2, null, false);
    }
    this.setState({
      chapters: result.chapters,
    });
    if (result.next) {
      this.setState({
        autoPlay: false,
        current: result.next,
        isLast: false,
      });
      // this.clickChapterItem(result.next);
    }
    await this.changeProgress(current, currentId);
    set(detail.userDefinedTrack, [currentId, 'finish'], true)
    await updateCourseTrack(detail.id, { userDefinedTrack: detail.userDefinedTrack })
    this.changePlayed();
  };
  gotoAuthor() {
    const { detail } = this.state;
    const author = detail?.author?.d || detail?.author || {};
    localStorage.setItem('author', JSON.stringify(author));
    this.props.router.push('/author');
  }
  getCourseItem() {
    let tmp = localStorage.getItem('courseItem');
    let res = {};
    if (tmp) {
      res = JSON.parse(tmp);
    }
    return res;
  }
  async onCollect() {
    let id = this.courseId
    await CourseApi.collectCourse(id)
    await this.fetchFavorites() 
  }
  joinPlan = async () => {
    let { detail } = this.state;
    if (detail.tracked) {
      return detail.id;
    }
    if (!this.onceJoinPlan) {
      this.onceJoinPlan = true;
      try {
        let res = {}
        const tracks = await CourseApi.getPlanList();
        const track = tracks.find(t => t.courseId === Number(this.courseId))
        if (track) {
          res.id = track.id
        } else {
          res = await CourseApi.joinPlan({ courseId: Number(this.courseId) })
        }
        this.setState(
          {
            detail: {
              ...detail,
              ...track,
              updateAt: detail.updateAt,
              tracked: true,
              trackId: res.id,
            },
          },
          () => {
            // this.changeProgress();
          }
        );

        return res.id;
      } finally {
        this.onceJoinPlan = false;
      }
    }
  };

  valuate(value) {
    //value为评定的几星
    if (!value) {
      return;
    }
    this.closeValuate();
    //请求接口
    CourseApi.valuateCourse(this.courseId, { est: value })
      .then(res => {
        this.setState({
          valuated: true,
          detail: { ...this.state.detail, userRate: value },
        });
        Toast.info('评价成功', 2, null, false);
      })
      .catch(() => {
        this.setState({
          valuated: false,
        });
        Toast.info('评价失败', 2, null, false);
      });
  }
  closeValuate() {
    this.setState({
      showValuate: false,
    });
  }

  startTimer = async () => {
    this.endTimer()
    this.timer = setTimeout(async () => {
      try {
        await this.updDuration(this.courseId)
      } finally {
        this.startTimer()
      }
    }, 60 * 1000)
  }

  endTimer = async () => {
    if (!this.timer) return
    clearTimeout(this.timer)
  }

  async updDuration(courseId) {
    await updateDuration(Number(courseId))
  }

  onPlay = () => {
    this.startTimer()
  }

  onPause = () => {
    this.endTimer()
  }

  render() {
    const {
      detail,
      collected,
      current,
      chapters,
      showValuate,
      valuated,
      autoPlay,
      isLast,
      networkType,
      favorites
    } = this.state;
    const poster = detail.cover;
    let joined = detail.tracked; //控制显示是否已加入
    // let percent = detail.progress ? detail.progress.percent : 0
    let progress = detail.progress || {};
    // let valuated = false //是否已点评
    const chaptersView = chapters.map((item, index) => {
      return (
        <CataLogItem
          detailId={detail.courseId}
          detailTrackId={detail.id}
          key={index}
          item={item}
          joined={joined}
          current={current}
          isLast={isLast}
          joinPlan={this.joinPlan}
          track={detail}
          clickItem={item => {
            this.clickChapterItem(item);
          }}
        />
      );
    });

    return (
      <div className='course-detail-page'>
        {current.video && current.video.url ? (
          <MyVideo
            onPause={this.onPause}
            onPlay={this.onPlay}
            courseId={this.courseId}
            current={current}
            track={detail}
            trackId={detail.id}
            networkType={networkType}
            joinPlan={() => this.joinPlan()}
            joined={joined}
            poster={poster}
            isLast={isLast}
            autoPlay={autoPlay}
            onFinish={data => this.onFinish(data)}
            changePlayed={() => this.changePlayed()}
          />
        ) : (
          <div
            style={{
              height: 210,
              width: '100%',
              background: `black url(${poster}) no-repeat center / contain`,
            }}
          ></div>
        )}

        {/* 评价弹框 */}
        {showValuate ? (
          <ValuateStar
            userRate={detail.userRate}
            valuate={value => this.valuate(value)}
            close={() => this.closeValuate()}
          />
        ) : null}
        <Tabs tabs={tabs} onTabClick={(tab, index) => {}}>
          {this.state.loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100% - 210px)',
                width: '100%',
              }}
            >
              <ActivityIndicator animating size='large' />
            </div>
          ) : (
            (tab, idx) =>
              idx === 0 ? (
                <div className='intro-box'>
                  <p className='title'>{detail.title}</p>
                  <div className='intro-info'>
                    <p style={{ marginTop: '5px' }}>
                      发布组织：
                      {detail.publisher ? detail.publisher.name : ''}
                    </p>
                    <p style={{ marginTop: '5px' }}>
                      发布时间：
                      <em>
                        {detail.updateAt ? formatDate(detail.updateAt) : null}
                      </em>
                    </p>
                    <p style={{ marginTop: '5px' }}>
                      评分：<span>{detail.rate || 5}分</span>
                    </p>
                    <p style={{ marginTop: '5px' }}>
                      课时：{detail.duration}
                    </p>
                    <p style={{ marginTop: '5px' }}>
                      课程时长： {Number.parseInt((detail.total_duration || 0) / 60 / 60)}小时{Number.parseInt((detail.total_duration || 0) / 60 % 60)}分钟{Number.parseInt(detail.total_duration || 0) % 60}秒
                    </p>
                    <div className='collect-box'>
                      <div className='c-left'>
                        <span onClick={() => this.onCollect()}>
                          <em className={favorites.includes(this.courseId) ? 'collected' : ''}></em>
                          收藏
                        </span>
                        {valuated ? (
                          <span className='valuated'>
                            <em></em>
                            已点评
                            {/* <i></i> */}
                          </span>
                        ) : (
                          <span
                            className='no-valuate'
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({
                                showValuate: true,
                              });
                            }}
                          >
                            <em></em>
                            点评
                          </span>
                        )}
                      </div>
                      <div className='c-right'>
                        {typeof progress.percent !== 'undefined' &&
                        progress.percent >= 0 &&
                        progress.percent < 100 ? (
                          <div className='studying'></div>
                        ) : progress.percent === 100 ? (
                          <div className='complete'></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {/* <p className="sub-title">讲师简介：</p> */}
                  {detail.author ? (
                    <div className='author-info'>
                      <div className='author' onClick={() => this.gotoAuthor()}>
                        <img src={detail.author?.d?.avatar || detail.author?.avatar} alt='avatar' />
                        <span>{detail.author?.d?.name || detail.author?.name}</span>
                      </div>
                      <div onClick={() => this.gotoAuthor()} className='link'>
                        查看介绍 <Icon type='right' size='xs' color='#646464' />
                      </div>
                    </div>
                  ) : null}
                  <p className='sub-title'>课程简介：</p>
                  <div className='cont'>{detail.description}</div>
                  {/* <div className="cont">
                                        {detail.author && detail.author.description}
                                    </div> */}
                  <p className='sub-title'>适用人群：</p>
                  <div className='cont'>{detail.targetTrainees}</div>
                </div>
              ) : (
                <div className='catalog-box'>{chaptersView}</div>
              )
          )}

          {/* <div className='comment-box'>
                        <CommentItem />
                        <CommentItem />
                    </div> */}
        </Tabs>

        {/* 评论输入框 */}
        <div className='comment-input'></div>
      </div>
    );
  }
}

class CataLogItem extends Component {
  static defaultProps = {
    item: {},
    index: '',
    clickItem: () => {},
  };
  render() {
    const {
      item,
      current,
      clickItem,
      joined,
      detailId,
      detailTrackId,
      joinPlan,
    } = this.props;

    const paragraphsView =
      item.paragraphs?.map((paragraph, innerIndex) => {
        
        let status = paragraph.status
        
        if (!joined) {
          //如果未加入学习计划，而且也不是培训计划里的，则不显示什么状态，
          status = ''
        }
        return (
          <div
            key={paragraph.id}
            className={`catalog-item bottom-line ${status}`}
            onClick={async () => {
              clickItem(paragraph)

              if (paragraph.pdf) {
                const track = await fetchCourseTrack(detailId)

                await CourseApi.changePlayed(detailId) // 更新查看课程次数

                browserHistory.push(
                  `/pdfDetail/${window.encodeURIComponent(paragraph.pdf.url)}?track_id=${
                    track.id
                  }&detail_id=${detailId}&duration=${paragraph.pdf.duration || 0}&item_id=${
                    paragraph.id
                  }`
                )
              }
            }}
          >
            <em className={`video-icon ${paragraph.pdf ? 'pdf' : ''}`}></em>
            <p className='title'>
              {innerIndex + 1} . {paragraph.name}
            </p>

            {/* 未加入学习中的什么状态都不显示 */}
            {!joined ? (
              <p className='status'>{current.id === paragraph.id ? null : null}</p>
            ) : (
              <p className='status'>
                {current.id === paragraph.id && !paragraph.pdf ? '播放中' : CHAPTER_STATUS[status]}
              </p>
            )}
          </div>
        )
      }) || null
    return (
      <div className='wraper'>
        <p style={{ padding: '15px 0  0 15px' }}>{item.name}</p>
        {paragraphsView}
      </div>
    );
  }
}

// class CommentItem extends Component {
//   render() {
//     return (
//       <div className='comment-item'>
//         <div className='avatar'>
//           <img src={tmpImg} alt='#' />
//         </div>
//         <div className='comment-info'>
//           <div className='info-wraper'>
//             <div className='info'>
//               <p className='name'>董小姐</p>
//               <p className='time'>2019/09/08</p>
//             </div>
//             <div className='rate'>xingxing</div>
//           </div>
//           <div className='comment'>
//             老师讲课比较幽默。课程比较入门，知识点丰富，感觉学起来挺轻松的。想问一下老师，课件哪儿可以下载？
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

class ValuateStar extends Component {
  state = {
    stars: 0,
  };
  componentDidMount() {
    let { userRate } = this.props;
    if (userRate) {
      this.setState({
        stars: userRate,
      });
    }
  }
  clickStar(i) {
    this.setState({
      stars: i + 1,
    });
  }
  sure() {
    this.props.valuate(this.state.stars);
  }
  close() {
    //重置评分
    this.setState({
      stars: 0,
    });
    //关闭弹窗
    this.props.close();
  }
  render() {
    let total = 5;
    let starView = [];
    const { stars } = this.state;
    for (let i = 0; i < total; i++) {
      starView.push(
        <span
          key={i}
          className={stars > i ? 'stared' : ''}
          onClick={() => this.clickStar(i)}
        ></span>
      );
    }
    return (
      <div
        className='valuate-box'
        onClick={() => {
          this.close();
        }}
      >
        <div
          className='body'
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className='title bottom-line'>课程评价</div>
          <div className='star-box'>{starView}</div>
          <div className='sure top-line' onClick={() => this.sure()}>
            确定
          </div>
        </div>
      </div>
    );
  }
}
