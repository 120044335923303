import React, { Component } from 'react';
import TestItem from '@/components/TestItem';
import { NavBar } from 'antd-mobile';
import Apis from '@/apis/';
import '@/styles/testCenterPage.less';
import { getOrgIdOnDomin } from '../util/common';

const { UserApi } = Apis;

export default class TestCenterPage extends Component {
  state = {
    value: '',
    user_profile: {},
    reports: [],
    theme: {},
  };
  async componentDidMount() {
    document.title = '考试中心';

    const user_profile = await UserApi.queryUserProp();
    this.setState({user_profile});
    

    let theme;
    try {
      theme = await UserApi.getTheme(getOrgIdOnDomin());
    } catch (e) {}
    this.setState({
      theme
    });


    const { recordset: reports } = await UserApi.getExamReport(
      theme.reportInstanceName || "exam",
      user_profile.employeeNo
    );
    this.setState({
      reports,
    });
  }
  render() {
    const { user_profile, theme, reports } = this.state;

    let x = (
      <div style={{ visibility: 'hidden', height: 0 }}>
        <form ref='fff' action={theme.exam || 'http://educenterexam.coreland.net/m/login.aspx'} method='POST'>
          <input
            type='text'
            readOnly={true}
            value={user_profile.employeeNo}
            name='txtUserName'
          />
          <input type='password' readOnly={true} value='123456' name='txtPwd' />
          <input
            type='hidden'
            name='__VIEWSTATE'
            value={theme.viewState || '/wEPDwULLTE4NzExMjM1MTdkZDdxR4I2SOLM12lF2rR4Af+5MhXt7TXmAXUDSu2Ll7Wa'}
          />{' '}
          <input type='hidden' name='__VIEWSTATEGENERATOR' value={theme.viewStateGenerator || 'A8FDB752'} />
          <input
            type='hidden'
            name='__EVENTVALIDATION'
            value={theme.eventValidation || '/wEdAAPrwujmnVZOKeButFhImguvY3plgk0YBAefRz3MyBlTcDPSlu16Yx4QbiDU+dddK1MpRaTol0CBZgM2DbLgtvLpRwWyckGEjp4Ps3q5y1XiLQ=='}
          />
          <button ref='s'>提交</button>
        </form>
      </div>
    );
    return (
      <div className='test-center-page'>
        {x}
        <NavBar
          mode='light'
          rightContent={
            <div
              onClick={() => {
                this.refs.fff.submit();
              }}
            >
              前往考试中心
            </div>
          }
        ></NavBar>
        <div className='bottom-line'></div>
        {reports.map((r, i) => (
          <TestItem
            key={i}
            title={r.Title}
            begin={r.BeginDate}
            end={r.EndDate}
            score={r.Score}
          />
        ))}
      </div>
    );
  }
}
