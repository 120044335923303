import React from 'react'
import { Tabs, Toast } from 'antd-mobile'
import '../styles/ReportPage.less'
import small_black_arrow from '../images/arrow_small@2x.png'
import ReactEcharts from 'echarts-for-react'
import Apis from '@/apis/'
import moment from 'moment'
import { fetchRankings } from '../apis/index'
// import CourseItem from '@/components/CourseItem1';
import UnDevelop from '@/components/UnDevelop'
import { get } from '../services/storage'
import CONFIG from '../config'

const UserApi = Apis.UserApi
const CourseApi = Apis.CourseApi

const tabs = [
  { title: <div className='nav_wrap'>积分概况</div> },
  { title: <div className='nav_wrap'>兑换商城(即将上线)</div> },
]

class XpPage extends React.Component {
  state = {
    option: {
      xAxis: {
        type: 'category',
        axisTick: { show: false },
        data: [],
      },
      yAxis: {
        show: false,
      },
      dataZoom: [
        {
          type: 'inside',
          startValue: 0,
          endValue: 0,
        },
      ],
      series: [
        {
          type: 'bar',
          data: [],
          itemStyle: {
            color: '#549EF8',
          },
          barWidth: 12,
          label: {
            normal: {
              show: true,
              position: 'top',
              distance: 15,
              align: 'center',
              verticalAlign: 'center',
              formatter: '{c}分',
              fontSize: 16,
              rich: {
                name: {
                  textBorderColor: '#fff',
                },
              },
            },
          },
        },
      ],
    },
    durations: {},
    curDuration: 0,

    courseList: [],
    currentIndex: '',
    durationlist: [],

    grade: {},
    rank: 0,
  }

  async componentDidMount() {
    document.title = '我的积分'
    const [durationlist, grade] = await Promise.all([
      this.getCredit('xp'),
      UserApi.queryUserRank(),
      this.getChartData('xp'),
      this.getPlan(),
      this.fetchRank(),
    ])

    this.setState({ durationlist, grade })
  }

  fetchRank = async () => {
    const userInfo = JSON.parse(get(CONFIG.userInfo))
    const { data } = await fetchRankings('xp')
    const rank = data.findIndex(_ => _.user_id === userInfo.id) + 1
    this.setState({ rank })
  }

  getCredit = async type => {
    const res = await UserApi.getCredits(type)

    return res.map(_ => ({
      ..._,
      amount: Number.parseInt(_.amount / 1000),
      trade_at: moment(Number(_.trade_at)),
    }))
  }

  // 获取 学分
  getChartData = async type => {
    const res = await this.getCredit(type)
    const durations = res.reduce((obj, item) => {
      const day = item.trade_at.format('MM.DD')
      if (!obj[day]) {
        obj[day] = {
          data: [],
          trade_at: item.trade_at,
        }
      }
      obj[day].data.push(item)
      return obj
    }, {})

    const xAxisData = Object.keys(durations).sort((a, b) => a - b)
    const barData = xAxisData.map(date => {
      return durations[date].data.reduce((count, item) => {
        count += item.amount
        return count
      }, 0)
    })

    this.setState({
      option: {
        ...this.state.option,
        xAxis: {
          ...this.state.option.xAxis,
          data: xAxisData,
        },
        series: [
          {
            ...this.state.option.series[0],
            data: barData,
          },
        ],
        dataZoom: [
          {
            ...this.state.option.dataZoom[0],
            startValue: xAxisData.length - 6,
            endValue: xAxisData.length - 1,
          },
        ],
        tooltip: {
          trigger: 'axis',
          showContent: false,
          axisPointer: {
            type: 'shadow',
            snap: true,
            label: {
              formatter: ({ value }) => {
                this.setState({ curDuration: value })
              },
            },
          },
        },
      },
      durations,
      curDuration: xAxisData[xAxisData.length - 1],
    })
  }

  creditChartClickHandler = ({ name }) => {
    this.setState({ curDuration: name })
  }

  getPlan = async () => {
    const res = await CourseApi.getPlanList()
    res.length > 2 && (res.length = 2)
    let courseList = res.map(item => {
      if (item.progress && item.progress.percent && item.progress.percent === 100) {
        item.progress.status = 'complete'
      } else {
        item.progress.status = 'studying'
      }
      return item
    })
    this.setState({
      courseList,
    })
  }

  handleCurIndex(index) {
    this.setState({
      currentIndex: index,
    })
  }

  onCancelPlan(item) {
    let id = item.id
    CourseApi.delePlan(id)
      .then(res => {
        Toast.info('取消学习计划成功', 2, null, false)
        let { courseList } = this.state
        let resData = []
        courseList.forEach(itemData => {
          if (itemData.id !== id) {
            resData.push(itemData)
          }
        })
        this.setState({
          courseList: resData,
        })
      })
      .catch(err => {
        Toast.info('取消学习计划失败', 2, null, false)
      })
  }

  clickItem(item) {
    let courseId = item.courseId
    this.props.router.push(`/course/detail/${courseId}`)
  }

  tabClickHander = async index => {
    // await this.getChartData(index ? 'credit' : 'credit')
  }

  render() {
    const { durations, curDuration, rank } = this.state
    const curDurationList = durations[curDuration]

    let continuous_day = 0
    const getDay = time => Number.parseInt(time / 1000 / 60 / 60 / 24)
    const today = getDay(Date.now())
    this.state.durationlist.every(_ => {
      if (getDay(_.trade_at._i) + continuous_day === today) {
        continuous_day++
        return true
      }
      if (getDay(_.trade_at._i) + continuous_day < today) {
        return false // 终止循环
      }
      return true
    })

    return (
      <div className='report_wrap'>
        <Tabs
          tabs={tabs}
          onTabClick={(_, index) => this.tabClickHander(index)}
          renderTabBar={props => (
            <div className='tabs_wrap'>
              <Tabs.DefaultTabBar {...props} />
            </div>
          )}
        >
          {/* 积分概况 */}
          <div className='credit_brief_wrap'>
            <header>
              <span>
                累计积分:
                <em>
                  {Object.values(durations).reduce((count, ds) => {
                    count += ds.data.reduce((c, d) => {
                      c += d.amount
                      return c
                    }, 0)
                    return count
                  }, 0)}
                </em>
              </span>
              <span>
                积分排名:
                <em>{rank || '-'}</em>
              </span>

              <div>
                查看规则
                <img src={small_black_arrow} alt='' />
              </div>
            </header>

            <div className='chart'>
              <ReactEcharts
                onEvents={{
                  click: this.creditChartClickHandler,
                }}
                style={{ height: '200px', width: '100%' }}
                option={this.state.option}
              />
            </div>

            <footer>
              <div className='footer_title_wrap'>
                <span className='footer_title_text'>
                  <span className='icon_blue'></span>
                  当日积分:
                  <em>
                    {!curDurationList
                      ? 0
                      : curDurationList.data.reduce((count, _) => {
                          count += _.amount
                          return count
                        }, 0)}
                  </em>
                </span>

                <span className='date'>
                  {curDurationList && moment(curDurationList.trade_at).format('YYYY/MM/DD')}
                </span>
              </div>

              <section className='list_wrap'>
                {curDurationList &&
                  curDurationList.data.map((_, i) => (
                    <div key={i}>
                      <span className='item_left'>
                        <span>{_.trade_at.format('HH:mm')}</span>
                        <em>{_.remark || '暂无'}</em>
                      </span>
                      <span className='item_right'>+{_.amount}分</span>
                    </div>
                  ))}
              </section>
            </footer>
          </div>

          {/* 兑换商城 */}
          <div style={{ height: 500, width: '100%' }}>
            <UnDevelop />
          </div>
        </Tabs>
      </div>
    )
  }
}

export default XpPage
