import React, { Component } from 'react'
import { InputItem, Button, Toast } from 'antd-mobile';
import Apis from '../apis/index'
import { browserHistory } from 'react-router'

import '../styles/forgetPw.less'
import EYE_YES from '../images/login_icon_eye_pre.png'
import EYE_NO from '../images/login_icon_eye_def.png'

const UserApi = Apis.UserApi

export default class ForgetPwPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canSee: false,
      phone: '',
      code: '',
      newPass: '',
      isPhone: false
    }
  }
  componentDidMount() {
    document.title = '忘记密码'
  }
  clickRight() {
    if (!this.state.isPhone) {
      Toast.info('请填写正确的手机号', 3, null, false)
      return
    }
    let phone = this.state.phone.toString().trim()
    UserApi.sendResetPassCode({
      username: phone
    }).then((res) => {
      Toast.info('发送成功', 3, null, false)
    })
  }
  validatePhone() {
    let value = this.state.phone.toString().trim()
    let reg = /^1([3|4|5|7|8|])\d{9}$/
    if (reg.test(value)) {
      this.setState({
        isPhone: true
      })
    } else {
      this.setState({
        isPhone: false
      })
    }
  }

  reset() {
    let username = this.state.phone.toString().trim(),
      password = this.state.newPass.toString().trim(),
      code = this.state.code.toString().trim()

    if (!username || !password || !code) {
      return
    }
    UserApi.resetPass({
      username,
      password,
      code
    }).then((res) => {
      Toast.info('修改成功', 2, null, false)
      browserHistory.push('/login')
    })
  }
  render() {
    let canCommit = this.state.phone && this.state.code && this.state.newPass
    return (
      <div className='forget-container'>
        <InputItem
          clear
          placeholder="请输入手机号"
          className='phone item'
          extra={<RightButton clickRight={() => this.clickRight()} flag={this.state.isPhone} />}
          value={this.state.phone}
          onChange={(value) => {
            this.setState({ phone: value })
            let reg = /^1([3|4|5|7|8|])\d{9}$/
            if (reg.test(value)) {
              this.setState({
                isPhone: true
              })
            } else {
              this.setState({
                isPhone: false
              })
            }
          }}
          onBlur={() => this.validatePhone()}
        >
          手机号：
        </InputItem>
        <InputItem
          clear
          placeholder="请输入验证码"
          className='code item'
          value={this.state.code}
          onChange={(value) => this.setState({ code: value })}
        >
          验证码：
        </InputItem>
        <InputItem
          clear
          type={this.state.canSee ? 'text' : 'password'}
          placeholder="请输入8位以上密码"
          className='password item'
          value={this.state.newPass}
          onChange={(value) => { this.setState({ newPass: value }) }}
          extra={<img src={this.state.canSee ? EYE_YES : EYE_NO} className='img-eye' alt='password' />}
          onExtraClick={() => {
            this.setState({
              canSee: !this.state.canSee
            })
          }
          }
        >
          新密码：
        </InputItem>
        <Button className={['reset-btn', canCommit ? 'can-click' : '']} onClick={() => this.reset()}>重置密码</Button>
      </div>
    )
  }
}

class RightButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      canClick: true,
      text: '获取验证码',
    }
  }
  clickRight() {
    const { clickRight, flag } = this.props
    if (flag && this.state.canClick) {
      this.countTime()
      clickRight()
    }
  }
  countTime() {
    let time = 60
    this.setState({
      canClick: false,
      text: time + 's'
    })
    this.timer = setInterval(() => {
      time--
      this.setState({
        text: time + 's'
      })
      if (time <= 0) {
        clearInterval(this.timer)
        this.setState({
          canClick: true,
          text: '获取验证码'
        })
      }
    }, 1000)
  }
  render() {
    const { flag } = this.props
    return (
      <div className='right-box' onClick={() => this.clickRight()}>
        <span className={flag && this.state.canClick ? 'click' : ''}>{this.state.text}</span>
      </div>
    )
  }
}