import React from 'react';
import ReactDOM from 'react-dom';
import 'antd-mobile/dist/antd-mobile.css';
import './styles/base.less';
import App from './AppContainer';
import * as serviceWorker from './serviceWorker';
import adaptive from './util/adaptive';
import Vconsole from 'vconsole'

if (process.env.NODE_ENV === 'development') {
    // new Vconsole()
}

// 设计图宽度
window.adaptive.desinWidth = 375;
// body 字体大小 会将body字体大小设置为 baseFont / 100 + 'rem'  750的设计图一般设置为28,640的设计图一般设置为24
window.adaptive.baseFont = 14;
window.adaptive.init();
let isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
window.isIOS = isIOS;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
