import React, { useEffect, useRef } from 'react';

import { updateCourseTrack } from '../apis/course'
import Apis from '@/apis/';
import { set, get } from 'lodash'
import { updateDuration } from '../apis'

const CourseApi = Apis.CourseApi;

/**
 * @description 固定间隔时间秒 定时执行 参数方法, 切屏回到页面也会触发该事件
 * @param {(...p: any[]) => any} fn
 */
const useInterval = (
  fn: (finish?: () => void) => any,
  ms: number = 1 * 1000
) => {
  const timer = useRef();

  useEffect(() => {
    const start = () => {
      timer.current = setTimeout(async () => {
        await fn(() => clearTimeout(timer.current));
        start();
      }, ms);
    };
    start();

    const handler = async () => {
      clearTimeout(timer.current);
      if (document.hidden) return;
      start();
    };

    document.addEventListener('visibilitychange', handler);

    return () => {
      clearTimeout(timer.current);
      document.removeEventListener('visibilitychange', handler);
    };
  }, []);
};


const useInterval1 = (cb, duration = 1 * 1000) => {
  
  const timer = useRef();

  useEffect(() => {
    const start = () => {
      timer.current = setTimeout(async () => {
        try {
          await cb(() => clearTimeout(timer.current));
        } finally {
          start();
        }
      }, duration);
    };
    start();

    return () => {
      clearTimeout(timer.current);
    };
  }, []);
}

const fetchCourseTrack = async (courseId) => {
  courseId = Number(courseId)
  const tracks = await CourseApi.getPlanList();
  const track = tracks.find(t => t.courseId === courseId)
  if (track) {
    return track
  } else {
    return await CourseApi.joinPlan({ courseId })
  }
}

const PdfDetail = props => {
  let { detail_id, duration, track_id, item_id } = props.location.query;
  duration = Number.parseInt(duration);

  const uesr_stayed_time_key = `couser_${detail_id}_paragraph_${item_id}_track_${track_id}_user_stayed_time`;

  /**
   * @description 获取已用时, 返回为number
   */
  // prettier-ignore
  const getUsedTime = () => Number.parseInt(localStorage.getItem(uesr_stayed_time_key)) || 0;

  /**
   * @description 检查是否结束, 记录完成情况
   * @returns
   */
  const checkIsFinished = async finish => {
    if (!duration || !detail_id || !track_id || !item_id) return;
    const used_time = getUsedTime();

    if (used_time < duration * 1000 * 0.85) return; // 小于预计时间的 85% 则不记录
    const track = await fetchCourseTrack(detail_id);
    // 已完成
    if (track.finished) {
      finish()
      return;
    }

    await CourseApi.changePlanCourseProgress(detail_id, item_id)

    finish();
  };

  useInterval(checkIsFinished, 30 * 1000);

  /**
   * @description 记录已用时间 每次执行时 加一秒
   */
  const countTime = async () => {
    if (!duration || !detail_id || !track_id || !item_id) return;
    const used_time = getUsedTime();
    localStorage.setItem(uesr_stayed_time_key, used_time + 1 * 1000);

    // 两分钟记录一次
    if (used_time > 1000 && used_time % (2 * 60 * 1000) < 1 * 1000) {
      const track = await fetchCourseTrack(detail_id)
      const keys = ['userDefinedTrack', item_id, 'learnTime']
      set(track, keys, (get(track, keys) || 0) + 2 * 60)
      await updateCourseTrack(track.id, {
        userDefinedTrack: track.userDefinedTrack,
      })
    }
  };

  useInterval(countTime);

  useInterval1(async () => {
    await updateDuration(Number(detail_id))
  }, 60 * 1000)
  let src = '';
  if (!props.params.url.includes('.pdf')) {
    src = `https://view.officeapps.live.com/op/embed.aspx?src=${props.params.url}`;
  } else {
    src = `http://pdf.xuanjiacd.com/web/viewer.html?file=${props.params.url}`;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        style={{ width: '100%', height: '100%' }}
        src={src}
        frameBorder={0}
      ></iframe>
    </div>
  );
};

export default PdfDetail;
