import React, { Component } from 'react'
import { Toast } from 'antd-mobile'
import "../styles/video.css";
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from 'video-react';
import { updateCourseTrack } from '../apis/course'
import { set, get } from 'lodash'


export default class MyVideo extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      showMask: false,
      showBigPlay: true,
      showToast: false
    }
    this.videoInfo = {}
    this.canPlay = false
    this.prevId = ''
    this.activePlayTime = 0
    this.play = this.play.bind(this);
  }

  componentDidMount() {
    let video = this.player.video.video
    // video.setAttribute('x5-video-player-type', 'h5')
    video.setAttribute('x5-video-player-fullscreen', 'true')
    video.setAttribute('webkit-playsinline', 'true')
    video.setAttribute('x-webkit-airplay', 'true')
    video.setAttribute('x5-playsinline', 'true')
    this.setState({
      showMask: true
    })
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    // this.duration = this.videoInfo.duration
    // this.initPlayerCurTime()
  }
  componentWillReceiveProps(nextProps, prevState) {
    if (!this.onlyOnce) {
      this.player.pause()
    }

    this.initPlayerCurTime(nextProps)

    if ((nextProps.autoPlay && !nextProps.isLast)) {
      this.player.play()
      return true
    }
  }

  componentWillUnmount() {
    this._changeStudyTime()
    clearInterval(this.studyTimer)
  }

  initPlayerCurTime = (props = this.props) => {
    const { track, current } = props
    setTimeout(() => {
      this.player.seek((get(track, ['userDefinedTrack', current.id, 'positionMillis']) || 0) / 1000)
      // Toast.info((get(track, ['userDefinedTrack', current.id, 'positionMillis']) || 0) / 1000)
    }, 0.5 * 1000)
  }
  
  play() {
    const { onPlay = () => {} } = this.props
    onPlay()

    if (!this.onlyOnce) {
      let flag = this.props.joined
      let fucntionjoined = (flag) => {
        this.onlyOnce = true
        if (!flag) {
          this.setState({
            showToast: true
          })
          setTimeout(() => {
            this.setState({
              showToast: false
            })
          }, 2000)
        }
        // if (this.props.joined) {
        //   Toast.info('该课程已加入学习记录，可在我的-学习记录中查看', 0, null, false)
        // }
      }
      setTimeout(fucntionjoined(flag), 2000)
    }
    if (this.videoInfo.id && this.prevId !== this.videoInfo.id) {
      this.prevId = this.videoInfo.id
      this.activePlayTime = 0
      this.playTimer && clearInterval(this.playTimer)
      this.playTimer = setInterval(() => {
        this.activePlayTime++
      }, 1000);
      this.props.changePlayed && this.props.changePlayed()
    } else {
      this.playTimer = setInterval(() => {
        this.activePlayTime++
      }, 1000);
    }
    this.setState({
      showMask: false
    })
    this.player.play()
    this.props.joinPlan && this.props.joinPlan()
    //计算播放时长
    this.initStudyTimer()
  }
  handleStateChange(state) {
    // copy player state to this component's state
    // console.log(state)
  }
  onCanPlay(e) {
    this.canPlay = true
  }
  onTimeUpdate() {
    let { player } = this.player.getState();
    if (player.seekingTime > 0) {
      this.prevTime = player.currentTime
    }
    // console.log(player.currentTime, player.seekingTime);
  }
  onError(e) {
    this.canPlay = false
  }
  onEnded(e) {
    /* this.videoInfo.duration * 0.85 */
    if (this.activePlayTime > this.videoInfo.duration * 0.85) {
      Toast.info('播放完成', 2, null, false)
      this.props.onFinish && this.props.onFinish(this.props.current)
      // // 增加一个计算学习时长的接口 ,播放完成后会自动调一次暂停，这里就不用调了
      // this._changeStudyTime(this.initStudyTimer)
      if (this.props.isLast) {
        // Toast.info('课程学习完毕', 2, null, false)
        this.player.pause()
        let video = this.player.video.video
        video.pause()
        if (!window.isIOS) { //处理微信浏览器中安卓手机上视频播放完成后出现微信推送的广告页面（垃圾微信浏览器，垃圾安卓）
          this.player.seek(0) //将进度条调至刚开始处
          this.player.play()
          setTimeout(() => {
            this.player.pause()
            video.pause()
          }, 100)
        }
      }
    } else {
      Toast.info('观看时长不足视频的总时长的85%，不能结束', 2, null, false)
      this.player.seek(this.prevTime || 0)
      this.player.pause()
      let video = this.player.video.video
      video.pause()
    }

    const { trackId, current, track } = this.props
    set(track, ['userDefinedTrack', current.id, 'learnTime'], this.studyTime)
    set(track, ['userDefinedTrack', current.id, 'positionMillis'], 0)
    updateCourseTrack(trackId, {
      userDefinedTrack: track.userDefinedTrack
    })
  }
  onPause() {
    const { onPause = () => {} } = this.props
    onPause()

    this.playTimer && clearInterval(this.playTimer)
    // 请求播放时长的接口
    if (this.studyTime) { //如果不为0，就是在播放，暂停之间有时间，则请求接口
      this._changeStudyTime(this.initStudyTimer)
    }

  }
  onPlaying() {
    if (this.state.showMask) {
      this.player.pause()
      return
    }
  }
  initStudyTimer() {
    this.studyTimer && clearInterval(this.studyTimer)
    this.studyTime = 0
    this.studyTimer = setInterval(() => {
      this.studyTime++
      // 整数分钟记录一次断点
      if (this.studyTime % 60 === 0) {
        this._changeStudyTime()
      }
    }, 1000)
  }
  async _changeStudyTime(fn) {
    if (this.studyTime > this.videoInfo.duration) this.studyTime = this.videoInfo.duration
    const { trackId, current, track } = this.props
    if (Array.isArray(track.userDefinedTrack)) {
      const obj = {}
      track.userDefinedTrack.forEach((t, i) => {
        if (t) {
          obj[i] = t
        }
      })
      set(track, 'userDefinedTrack', obj)
    }
    set(track, ['userDefinedTrack', `${current.id}`, 'learnTime'], this.studyTime)
    set(track, ['userDefinedTrack', `${current.id}`, 'positionMillis'], this.player.getState().player.currentTime * 1000)
    await updateCourseTrack(trackId, {
      userDefinedTrack: track.userDefinedTrack
    })
    fn && fn.apply(this)
  }

  render() {
    const { current, autoPlay, joined, networkType } = this.props
    const { showMask, showToast } = this.state
    let size = current.video ? current.video.size : 0
    if (size) {
      size = Math.ceil(size / 1024 / 1024)
    } else {
      size = 0
    }
    let showFlow = networkType === 'wifi' ? false : true
    let tmpSrc = ''
    this.videoInfo = { id: current.id, duration: current.video ? current.video.duration : 0 }
    // console.log(current)
    let src = current.video ? current.video.url : tmpSrc
    return (
      <div className="video-box">
        <Player
          ref={player => {
            this.player = player;
          }}
          width='100%'
          height='100%'
          fluid={false}
          onPlaying={() => this.onPlaying()}
          onPause={() => { this.onPause() }}
          onError={(e) => this.onError(e)}
          onCanPlay={(e) => { this.onCanPlay(e) }}
          onEnded={(e) => this.onEnded(e)}
          onTimeUpdate={() => { this.onTimeUpdate() }}
          playsInline={true}
          autoPlay={autoPlay}
          onPlay={() => this.play()}
          webkit-playsinline={true}
          src={src}
        >
          <LoadingSpinner />
          {
            <BigPlayButton position="center" className={'big-play-button-hide'} />
          }
          <ControlBar autoHide={false} />
        </Player>
        {
          showMask ?
            <div className='play-mask'>
              {
                showFlow ?
                  <p className="tip" >
                    正在使用非WIFI网络，播放将产生<em>{size}M</em>费用
                    </p>
                  : null
              }
              <div className="play-btn" onClick={() => this.play()}>
                {joined ?
                  <em className="continue-study" onClick={() => this.initPlayerCurTime()}><i></i>继续播放</em>
                  :
                  <em className="start-study"><i></i>开始学习</em>
                }
              </div>
            </div>
            : null
        }
        {/* {
          showToast ?
            <MyToast /> : null
        } */}
      </div>
    )
  }
}


class MyToast extends Component {
  render() {
    return (
      <div className="my-toast" >
        <div className="content">
          <div>该课程已加入学习记录，可在我的-学习记录中查看</div>
        </div>
      </div>
    )
  }
}
