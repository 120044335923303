import React, { Component } from 'react'
import { Toast, Picker } from 'antd-mobile'
import CourseItem from '@/components/CourseItem1'
import '@/styles/taskPage.less'
import Apis, { fetchCourseInfo, fetchCourseNode } from '@/apis/'
import DOWN_ICON from '@/images/kecheng_icon_arrow_down@2x.png'
import { toggleShowError } from '../config/interceptors'
const CourseApi = Apis.CourseApi

// const compulsory = false
export default class TaskPage extends Component {
  state = {
    courseList: [],
    currentIndex: '',
    curStatus: '未完成课程',
  }

  async componentDidMount() {
    document.title = '最近学习'

    let courseList = await CourseApi.getPlanList()

    courseList.sort((a, b) => b.updateAt - a.updateAt)
    toggleShowError(true)
    for (let track of courseList) {
      try {
        track.course = (await fetchCourseInfo(track.courseId)).data
        track.paragraphCount = await fetchCourseNode(track.courseId)
      } catch (e) {
        courseList = courseList.filter(_ => _ !== track)
      } finally {
        track.pro = track.paragraphCount
          ? Number.parseInt((track.progress.length / track.paragraphCount) * 100)
          : 0
      }
    }
    toggleShowError(false)

    this.setState({
      courseList,
    })
  }
  clickItem(item) {
    let courseId = item.courseId
    // localStorage.setItem('courseItem', JSON.stringify(item))
    this.props.router.push(`/course/detail/${courseId}`)
  }
  handleCurIndex(index) {
    this.setState({
      currentIndex: index,
    })
  }
  onCancelPlan(item) {
    let id = item.id
    CourseApi.delePlan(id)
      .then(res => {
        Toast.info('取消学习计划成功', 2, null, false)
        let { courseList } = this.state
        let resData = []
        courseList.forEach(itemData => {
          if (itemData.id !== id) {
            resData.push(itemData)
          }
        })
        this.setState({
          courseList: resData,
        })
      })
      .catch(err => {
        Toast.info('取消学习计划失败', 2, null, false)
      })
  }
  render() {
    let { courseList, currentIndex, curStatus } = this.state

    let listView = courseList
      .filter(_ => {
        if (curStatus === '未完成课程') {
          return _.pro < 100
        }
        return _.pro === 100
      })
      .map((item, index) => (
        <CourseItem
          canSlide={true}
          key={index}
          currentIndex={currentIndex}
          onSetCurIndex={index => this.handleCurIndex(index)}
          item={{
            ...item,
            date: item.createAt,
            status: curStatus === '未完成课程' ? 'studying' : 'complete',
            showProgress: true,
            percent: item.pro,
            cancelText: '计划',
          }}
          onCancel={item => {
            this.onCancelPlan(item)
          }}
          onClick={item => this.clickItem(item)}
        />
      ))
    return (
      <div className='task-page'>
        <div className='tab-box bottom-line'>
          <p>
            排序: <span>上次学习时间</span>
          </p>
          <Picker
            data={[
              {
                value: '未完成课程',
                label: '未完成课程',
              },
              {
                value: '已完成课程',
                label: '已完成课程',
              },
            ]}
            onChange={v => this.setState({ curStatus: v[0] })}
            value={[this.state.curStatus]}
            cols={1}
          >
            <p>
              {this.state.curStatus}
              <img src={DOWN_ICON} alt='' />
            </p>
          </Picker>
        </div>

        <div>{listView}</div>
      </div>
    )
  }
}
