class UniversalQueryBuilder {
  constructor(state) {
    this.state = state || {
      queries: [],
      pageSize: 10,
      pageNumber: 0,
      qc: 'and',
      order: 'asc',
      sort: '',
      sortType: 'integer',
    }
  }
  pagingOff() {
    this.state = { ...this.state, pageSize: undefined, pageNumber: undefined }
  }
  /**
   * @description
   * @param {*} field
   * @param {'asc' | 'desc'} [order='asc']
   * @param {'double' | 'varchar' | 'integer'} [sortType='integer']
   * @memberof UniversalQueryBuilder
   */
  setSort(field, order = 'asc', sortType = 'integer') {
    //Fill empty string into field for turning off the sorting feature.
    this.state = { ...this.state, order, sort: field, sortType }
  }
  pageingOn() {
    this.state = { ...this.state, pageSize: 10, pageNumber: 0 }
  }
  addQuery(
    field,
    value,
    operator = 'eq',
    type = 'string',
    id = Date.now().toString()
  ) {
    this.state.queries.push({ id, field, value, operator, type })
    return id
  }
  removeQuery(id) {
    this.state.queries = this.state.queries.filter((v) => v.id !== id)
  }
  listQueries() {
    return JSON.parse(JSON.stringify(this.state.queries))
  }
  go2page(n) {
    if (!Number.isInteger(n)) {
      throw new Error('invalid page number')
    }
    if (typeof this.state.pageNumber === 'number') {
      this.state.pageNumber = n
    }
  }
  setPageSize(n) {
    if (!Number.isInteger(n)) {
      throw new Error('invalid page number')
    }
    if (typeof this.state.pageSize === 'number') {
      this.state.pageSize = n
    }
  }
  toQueryString() {
    let res = []
    res.push(
      this.state.queries
        .map((v) => `q=${v.field}&v=${v.value}&op=${v.operator}&t=${v.type}`)
        .join('&')
    )
    if (typeof this.state.sort === 'string' && this.state.sort.length > 0) {
      res.push(
        `sort=${this.state.sort}&order=${this.state.order}&st=${this.state.sortType}`
      )
    }
    if (
      Number.isInteger(this.state.pageNumber) &&
      Number.isInteger(this.state.pageSize)
    ) {
      res.push(
        `page_number=${this.state.pageNumber}&page_size=${this.state.pageSize}`
      )
    }
    res = res.filter((v) => v.length > 0)
    res.push(`qc=${this.state.qc}`)
    return res.join('&')
  }
  getState() {
    return this.state
  }

  setConnector(c) {
    this.state.qc = c === 'or' ? 'or' : 'and'
  }
}

export default UniversalQueryBuilder
