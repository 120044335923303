import React, { Component } from 'react'
import CourseItem from '@/components/CourseItem'
import { fetchCategories, getCourseList } from '@/apis/'
import { PullToRefresh, ListView, ActivityIndicator } from 'antd-mobile'
import { promisify } from '../util/common'
import UQB from '../util/UQB'

const pageSize = 8
export default class LastestCoursePage extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    })
    this.state = {
      dataSource,
      courseList: [],
      useBodyScroll: true,
      refreshing: false,
      isLoading: false,
      hasMore: false,
      height: document.documentElement.clientHeight,
      total: 0,
      page: 0,
      showFooter: false, //如果数据只有几条，则不显示footer
    }
  }

  pss = promisify(this.setState.bind(this))

  async componentDidMount() {
    document.title = '最新课程'
    await this.fetchCourse({ page: 0 })
  }

  fetchCourse = async ({ page, isAdd }) => {
    await this.pss({ isLoading: true })

    try {
      const {
        data: { id },
      } = await fetchCategories()

      const uqb = new UQB()
      uqb.setSort('updateAt', 'desc', 'double')
      uqb.setPageSize(pageSize)
      uqb.go2page(page)

      let { data: courseList, headers } = await getCourseList(id, uqb)

      const total = Number(headers.count) || 0

      this.hasMore = (page + 1) * pageSize < total

      if (isAdd) {
        courseList = this.state.courseList.concat(courseList)
      }

      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(courseList),
        // height: hei,
        refreshing: false,
        isLoading: false,
        courseList,
        hasMore: this.hasMore,
        showFooter: total >= pageSize,
        page,
      })
    } finally {
      await this.pss({ isLoading: false })
    }
  }

  onRefresh = async () => {
    await this.pss({ refreshing: true, showFooter: false })

    await this.fetchCourse({ page: 0 })
    await this.pss({ refreshing: false })
  }
  onEndReached = async () => {
    let { isLoading, page, hasMore } = this.state
    if (isLoading || !hasMore) {
      return
    }

    await this.fetchCourse({ page: page + 1, isAdd: true })
  }
  clickItem(item) {
    let id = item.id
    this.props.router.push(`/course/detail/${id}`)
  }
  render() {
    const { showFooter, dataSource, isLoading, hasMore, refreshing } = this.state
    const row = (rowData, sectionID, rowID) => {
      let item = rowData
      return (
        <CourseItem
          style={{ wraperStyle: { marginBottom: 0 } }}
          key={item.id}
          onClick={item => this.clickItem(item)}
          item={{
            ...item,
            date: item.updateAt,
            company: item.publisher.name,
            myAuthor: item.author ? item.author.name : '',
            myPublisher: item.publisher.name,
          }}
        />
      )
    }

    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          background: '#F3F4F8',
          height: 10,
        }}
      />
    )

    return (
      <div className='lastest-course' style={{ height: '100%', background: '#F3F4F8' }}>
        <ListView
          ref={el => (this.lv = el)}
          dataSource={dataSource}
          renderRow={row}
          style={{
            height: '100%',
            boxSizing: 'border-box',
          }}
          renderSeparator={separator}
          renderFooter={() => {
            if (showFooter) {
              return (
                <div
                  style={{
                    padding: 10,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {isLoading ? (
                    <ActivityIndicator text='loading...' />
                  ) : hasMore ? (
                    '上拉加载更多'
                  ) : (
                    '没有更多了'
                  )}
                </div>
              )
            }
          }}
          pullToRefresh={<PullToRefresh refreshing={refreshing} onRefresh={this.onRefresh} />}
          onEndReached={this.onEndReached}
          pageSize={pageSize}
        />
      </div>
    )
  }
}
