import React, { Component } from 'react';
import { Provider } from 'react-redux';
import Route from './routes/index';
import store from './store/index';

class AppContianer extends Component {
    render() {
        return (
            <Provider store={store}>
                <Route />
            </Provider>
        );
    }
}

export default AppContianer;
