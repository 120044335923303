import config from '../config'
import { get } from '../services/storage'

export function getUserInfo() {
  // let tmp = sessionStorage.getItem('userInfo');
  let tmp = localStorage.getItem('userInfo')
  let res = ''
  if (tmp) {
    res = JSON.parse(tmp)
  }
  return res
}

export function getToken() {
  let userInfo = getUserInfo()
  let token = userInfo ? userInfo.token : ''
  return token
}

// _时间格式化
function _formatDate(date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : _padLeftZero(str))
    }
  }
  return fmt
}
// _时间格式化
function _padLeftZero(str) {
  return ('00' + str).substr(str.length)
}

// 时间格式化
export function formatDate(s) {
  var date = new Date(s)
  return _formatDate(date, 'yyyy/MM/dd')
}

//课程中心的页面只显示月日
export function dateToMD(s) {
  var date = new Date(s)
  return _formatDate(date, 'MM-dd')
}

// 币币页面的时间
export function formatTime(s) {
  var date = new Date(s)
  return _formatDate(date, 'hh:mm')
}
//消息页面的时间
export function formatTimeMessage(s) {
  let date = new Date(s)
  return _formatDate(date, 'yy/MM/dd hh:mm')
}

export function videoTime(time) {
  time = time || 0 //单位为秒
  let hours = Math.floor(time / 60 / 60)
  hours = _mypadLeftZero(hours)
  let minites = Math.floor((time / 60) % 60)
  minites = _mypadLeftZero(minites)
  let times = Math.floor(time % 60)
  times = _mypadLeftZero(times)
  if (hours === '00') {
    return minites + ':' + times
  } else {
    return hours + ':' + minites + ':' + times
  }
}

function _mypadLeftZero(str) {
  str = str ? str + '' : '0'
  if (str.length === 1) {
    str = '0' + str
  }
  return str
}

export function getOrgIdOnDomin() {
  return window[config.orgIdKey] || 14184
}

export const promisify = fn => (...args) =>
  new Promise((resolve, reject) => {
    args.push(resolve)
    fn(...args)
  })
