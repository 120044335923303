import ins from '../config/interceptors'
import UQB from '../util/UQB'

import AuthApi from './auth'
import UserApi from './user'
import CourseApi from './course'
import TrainApi from './train'
import RankApi from './rank'
import MessageApi from './message'

export default {
  AuthApi,
  UserApi,
  CourseApi,
  TrainApi,
  RankApi,
  MessageApi,
}

// 获取推荐的课程列表
export const fetchRecommendCourses = (id, setUqb = uqb => uqb) => {
  const uqb = new UQB()
  uqb.addQuery('featured', 'true', 'eq', 'boolean')
  setUqb(uqb)
  return ins.get(`/courseinterface/v1/categories/${id}/courses/?${uqb.toQueryString()}`)
}

// 获取课程的目录
export const fetchCategories = data => ins.get(`/courseinterface/v1/categories`, data)

// 课程中心的课程列表
export const getCourseList = (id, query) =>
  ins.get(`/courseinterface/v1/categories/${id}/courses${query ? `?${query.toQueryString()}` : ''}`)

// 查询用户积分，排名等
export const fetchUserRank = data => ins.get(`/rankinginterface/v1/rankings/`, { params: data })

export const fetchCourseInfo = courseId => ins.get(`/courseinterface/v1/courses/${courseId}`)

export const fetchCourseNode = async (courseId, data?) => {
  try {
    const res = await ins.head(`/courseinterface/v1/courses/${courseId}/paragraphs`)

    return Number(res.headers.count)
  } catch (e) {
    return 0
  }
}

export const fetchCourseDetail = async courseId => {
  try {
    const { data: chapters } = await ins.get(`/courseinterface/v1/courses/${courseId}/chapters/`)

    await Promise.all(
      chapters.map(async chapter => {
        try {
          const { data: paragraphs } = await ins.get(
            `/courseinterface/v1/courses/${courseId}/chapters/${chapter.id}/paragraphs/`
          )
          await Promise.all(
            paragraphs.map(async paragraph => {
              try {
                const { data: content } = await ins.get(
                  `/courseinterface/v1/course-contents/${paragraph['content_id']}`,
                  {}
                )
                Object.assign(paragraph, { content })
              } catch (e) {
                console.error(e)
              }
            })
          )
          Object.assign(chapter, { paragraphs })
        } catch (e) {
          console.error(e)
        }
      })
    )

    return chapters
  } catch (e) {
    console.error(e)
    return []
  }
}

export const fetchTheme = async orgId => {
  return ins.get(`/authinterface/v1/page-configs/${orgId}`)
}

/**
 * @description
 * @param {*} userId
 * @param {"xp" | "duration" | "credit" | "online"} account
 * @param {*} start
 * @param {*} end
 */
export const fetchUserBalance = (userId, account, start, end) =>
  ins.get(`/traineeinterface/v1/users/${userId}/balance`, { params: { account, start, end } })

/**
 * @description
 * @param {"xp" | "duration" | "credit" | "online"} account
 * @param {*} start
 * @param {*} end
 */
export const fetchCredits = (account, startAt, endAt) =>
  ins.get(`/traineeinterface/v1/credits`, { params: { account, s: startAt, e: endAt } })

/**
 * @description
 * @param {"xp" | "duration" | "credit" | "online"} account
 * @param {number[]} org
 * @param {number} moment
 */
export const fetchRankings = (account, org, moment = Date.now()) =>
  ins.get(`/rankinginterface/v1/rankings`, { params: { account, org, moment } })

export const updateDuration = courseId => ins.post(`/userinterface/v1/durations`, { courseId })

export const fetchTracks = () => ins.get(`/traineeinterface/v1/course-tracks/`)