import React from 'react'
import info from '../gitInfo.json'
import moment from 'moment'

const DevInfoPage = () => {
  return (
    <div
      style={{
        whiteSpace: 'pre-wrap',
      }}
    >
      {JSON.stringify(
        info,
        (key, value) => {
          if (key.includes('date')) {
            return moment(value).format('YYYY/MM/DD HH:mm:ss')
          }
          return value
        },
        2
      )}
    </div>
  )
}

export default DevInfoPage
