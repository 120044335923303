import React, { Component } from 'react'

export default class TaskPage extends Component {
  render() {
    return (
      <div className='404-page'>
        404 not found
      </div>
    )
  }
}