import { axiosget, axiospost, axiospatch } from '../config/axios';

let prefix = '/userinterface/v1';

//查询组织内所有用户
export function queryAllUser(data) {
    return axiosget(prefix + '/orgs/1/users', data);
}

//查询用户信息
export function queryUserProp(data) {
    return axiosget('/traineeinterface/v1/profile', data);
}

//查询用户积分，排名等
export function queryUserRank(data) {
    ///rankinginterface/v1/orgs/1/rankings
    return axiosget('/userinterface/v1/properties', data);
}

//发送重置密码短信
export function sendResetPassCode(data) {
    return axiospost(prefix + '/sms', data);
}

//忘记密码的重置密码
export function resetPass(data) {
    return axiospost(prefix + '/password', data);
}
//修改密码
export function modifyPw(data) {
    return axiospost('/userinterface/v1/newpwd', data);
}

//上传图片
/**
 *
 *
 * @export
 * @param {*} data
 * @returns {Promise<{name: string, url: string}>}
 */
export function upload(data) {
    return axiospost('/uploadinterface/v1/upload', data);
}

//上传头像
export function uploadAvatar(data) {
    return axiospost('/uploadinterface/v1/avatar', data);
}

//修改用户头像
export function modifyAvatar(data) {
    return axiospatch('/traineeinterface/v1/avatar', data);
}

//获取网站的一些设置什么的如轮播图
export function getCommonConfig(data) {
    return axiosget(`/userinterface/v1/config`, data);
}

// 获取 当前url 对应的 org 对应的 logo 和 按钮颜色
export function getTheme(orgId) {
    return axiosget(`/authinterface/v1/page-configs/${orgId}`)
}

// 提交反馈
export function addFeedback(data) {
    return axiospost('/traineeinterface/v1/feedbacks', data);
}

// 获取学分/积分/学时
export function getCredits(account, data = {}) {
    return axiosget(
        `/traineeinterface/v1/credits?account=${account}&s=${data.s ||
            0}&e=${data.e || Date.now()}`
    );
}

export function getExamReport(instanceName, employeeNo) {
    return axiosget(`/${instanceName}/reports/${employeeNo}`)
}

export default {
    queryAllUser,
    queryUserProp,
    sendResetPassCode,
    resetPass,
    queryUserRank,
    modifyPw,
    modifyAvatar,
    upload,
    uploadAvatar,
    getCommonConfig,
    getTheme,
    addFeedback,
    getCredits,
    getExamReport,
};
