import React, { Component } from 'react'
import '../styles/courseItem.less'
import { formatDate, dateToMD, videoTime } from '@/util/common'

import IMG_TMP from '../images/tmp2.png'
import STATUS_COMPLETED from '../images/liebiao_pic_yiwanc@2x.png'
import STATUS_UNCOMPLETED from '../images/liebiao_pic_weiwanc@2x.png'
import STATUS_STUDYING from '../images/liebiao_pic_xuexizhong@2x.png'
import STATUS_EXPIRED from '../images/liebiao_pic_yiguoqi@2x.png'
import STATUS_UNSTART from '../images/liebiao_pic_weikais@2x.png'

export default class CourseItem extends Component {
  static defaultProps = {
    item: { //临时的显示数据
      title: '电动机停机故障定位三步法',
      cover: IMG_TMP,
      rate: 4.9,
      company: '攀钢钒钛股份',
      date: 1564735807270,
      duration: 30,
      status: '',
      percent: 0,
      cancelText: '收藏',
      myAuthor: '',
      myPublisher: '',
      showProgress: false, //展示完成了多少
      showEnd: false //默认为展示开始时间，只有培训时展示截止时间
    },
    style: {},
    index: '',
    currentIndex: '',
    canSlide: false,
    clickItem: () => { },
    onSetCurIndex: () => { }
  }
  constructor(props) {
    super(props)
    this.state = {
      hasTransition: false
    }
  }
  touchStart(e) {
    this.startX = e.touches[0].pageX
    this.startY = e.touches[0].pageY
  }
  touchMove(e, index) {
    this.currentX = e.touches[0].pageX
    this.moveX = this.currentX - this.startX
    this.moveY = e.touches[0].pageY - this.startY
    //纵向移动则返回
    if (Math.abs(this.moveY) > Math.abs(this.moveX)) {
      return
    }
    //滑动超过一定距离时，才触发
    if (Math.abs(this.moveX) < 10) {
      return
    } else {
      this.setState({
        hasTransition: true
      })
    }
    this.props.onSetCurIndex(index)
  }
  touchEnd(e) {
    this.setState({
      hasTransition: true
    })
  }
  componentDidMount() {
    this.startX = 0
    this.startY = 0
    this.currentX = 0
    this.moveX = 0
    this.moveY = 0
  }

  cancelCollect(e, item) {
    e.stopPropagation();
    this.setState({
      hasTransition: false
    })
    this.props.onCancel && this.props.onCancel(item)
  }
  dealStatus(status) {
    // let res = ''
    switch (status) {
      case 'complete':
        return STATUS_COMPLETED
      case 'studying':
        return STATUS_STUDYING
      case 'expired':
        return STATUS_EXPIRED
      case 'unStart':
        return STATUS_UNSTART
      default:
        return null
    }
  }
  clickItem() {
    const { item } = this.props
    if (!item.progress) {
      item.progress = {}
    }
    localStorage.setItem('courseItem', JSON.stringify(item))
    // let id = item.id
    // this.props.router.push(`/course/detail/${id}`)
    this.props.onClick && this.props.onClick(item)
  }

  render() {
    const { item, index, currentIndex, canSlide, style } = this.props
    const { hasTransition } = this.state
    let status = this.dealStatus(item.status)
    // 左滑时，出现del，右滑时，恢复原位，距离为操作按钮大小
    // 也可以将滑动距离作为移动距离，但是效果不太好
    const distance = this.moveX >= 0 ? 0 : -0.6
    let moveStyle = {}
    if (canSlide && hasTransition && currentIndex === index) {
      moveStyle.transform = `translateX(${distance}rem)`
      moveStyle.WebkitTransform = `translateX(${distance}rem)`
      moveStyle.transition = 'transform 0.3s ease'
      moveStyle.WebkitTransition = 'transform 0.3s ease'
    }
    return (
      <div className="course-item-wraper" style={style.wraperStyle} onClick={() => this.clickItem()}>
        <div className='course-item'
          style={{ ...moveStyle, backgroundImage: `url(${status})` }}
          onTouchStart={(e) => this.touchStart(e)}
          onTouchMove={(e) => this.touchMove(e, index)}
          onTouchEnd={e => this.touchEnd(e)}
        >
          <div className='left'>
            <img src={item.cover} alt="#" />
            <div className="play-mask">
              <em>{item.played}</em>
              <i>{item.videoDuration ? videoTime(item.videoDuration) : null}</i>
            </div>
          </div>
          <div className="right">
            <p className="title ellipsis">{item.title}</p>
            <p className="date" style={{ fontSize: '12px' }}>
              <span className="tag">{item.myAuthor}</span>
              <span className="tag">{item.myPublisher}</span>
              {/* <em>
                {
                  item.showEnd ? '截止：' : null
                }
                {formatDate(item.date)}
              </em>
              {
                item.showProgress ?
                  <em style={{ marginLeft: '8px' }}>
                    已学习：{item.percent || 0}%
                </em> :
                  null
              } */}
            </p>
            <div className="info">
              <span className='month-day'>{dateToMD(item.date)}</span>
              <Stars stars={item.rate} />
              {/* <span>评分：{item.rate}</span>
              <em>学时：{item.duration}学时</em> */}
            </div>
          </div>
          <div className="cancel-collect" onClick={(e) => this.cancelCollect(e, item)}>
            <em>取消</em>
            <em>{item.cancelText}</em>
          </div>
        </div>
      </div >
    )
  }
}

class Stars extends Component {
  filterStar(val) {
    val = Number(val) || 0
    let res = val.toFixed(1)
    return res
  }
  render() {
    let rate = this.props.stars || 0
    let stars = rate // Math.floor(rate)
    let total = 5
    let starView = []

    for (let i = 0; i < total; i++) {
      let classNames = ''
      if (stars - 1 >= i) {
        classNames = 'stared'
      } else if (stars - i >= 0.5 && stars - i < 1) {
        classNames = 'half-star'
      }
      starView.push(
        <div key={i} className='gray-star-box'>
          <span className="gray-star"></span>
          <span className={`abs ${classNames} `} ></span>
        </div>
      )
    }
    if (rate>0) {
      starView.push(
        <em className="rate" >{this.filterStar(rate)}</em > 
      )
    }else {
      starView.push(
        <em className="rate-zero">0.0</em>
      )
    }
    return (
      <div className="star-box" >
        {starView}
      </div >
    )
  }
}