import React, { Component } from 'react'
import axios from 'axios';
import { Link } from 'react-router'
import { InputItem, Button, Toast, ActivityIndicator } from 'antd-mobile'
import { browserHistory } from 'react-router'
import '../styles/login.less'
import Apis, { fetchTheme } from '../apis/index'
// import icon_name from '../images/icon_name.png'
// import icon_password from '../images/icon_password.png'
// import icon_logo from '../images/icon_logo.png'
import icon_logo from '../images/icon_logos.jpg'
import Socket from '../util/socket'
import { getOrgIdOnDomin, getUserInfo } from '@/util/common'
import { get } from '../services/storage'
import config from '../config'

const { AuthApi } = Apis

export default class OnePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
          loading: true,
        }
      }
    

      componentDidMount(){
        setTimeout(() =>{
            AuthApi.login({
                username: this.props.location.query.phone,
                password: '123456',
              }).then(res => {
                //登录成功连接socket
                window.socket = new Socket()
                let userInfo = res ? JSON.stringify(res) : ''
                localStorage.setItem('userInfo', userInfo)
                browserHistory.push('/')
              }).catch(err => {
                Toast.success('注册中', 2, null, false)
                axios.post('https://study.tqit.com/authinterface/v1/admin/login',{
                    username: "17729835609", password: "li123456"
                }).then(res => {
                    let token = res.data.token
                    axios.post(' https://study.tqit.com/admininterface/v1/users/',{
                        phone:this.props.location.query.phone
                    },{
                        headers: {
                            authorization: 'Bearer ' + token,
                          }
                    }).then(res => {
                        axios.post('https://study.tqit.com/admininterface/v1/orgs/12145/members/',{
                            userId:res.data.id
                        },{
                            headers: {
                                authorization: 'Bearer ' + token,
                              }
                        }).then(res => {
                            AuthApi.login({
                                username: this.props.location.query.phone,
                                password: '123456',
                              }).then(res => {
                                //登录成功连接socket
                                window.socket = new Socket()
                                let userInfo = res ? JSON.stringify(res) : ''
                                localStorage.setItem('userInfo', userInfo)
                                browserHistory.push('/')
                              })
                        })
                    })
                })
              })
        },2000)
      }

      render() {
        const { loading } = this.state

        if (loading) {
            return (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}
              >
                <ActivityIndicator text='正在加载' />
              </div>
            )
          }
      }
}