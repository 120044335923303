import React, { Component } from 'react'
import '../styles/messageDetail.less'
import Apis from '@/apis/'
import { browserHistory } from 'react-router'
import EMPTY_PIC from '../images/empty_pic_message@2x.png'
// import { Icon, List } from 'antd-mobile';
import { formatTimeMessage } from '@/util/common'
import { Toast } from 'antd-mobile'


const MessageApi = Apis.MessageApi

export default class MyMessage extends Component {
  state = {
    messageList: []
  }
  componentDidMount() {
    document.title = '我的消息'
    MessageApi.getMyMessageList().then((res) => {
      let messageList = res || []
      // res.forEach((item) => {
      //   if (item.unread) {
      //     messageList.push(item)
      //   }
      // })
      this.setState({
        messageList
      })
    })
  }
  clickItem(item) {
    let msgId = item.id
    //改变他的已读未读状态
    MessageApi.changeMessageStatus(msgId)

    if (item.handler === 'router') {
      switch (item.handlerInfo.path) {
        case 'formDetails': {
          browserHistory.push(`/train`)
          break
        }
        default: {
          Toast.info('暂不支持该类型消息')
        }
      }
    }
    
    if (item.type && item.type === 'studyplan') {
      browserHistory.push(`/train/detail/${item.planId}`)
    }
  }
  render() {
    const { messageList } = this.state
    return (
      <div className="my-message-detail">
        {
          messageList.length ?
            <div className="have-data">
              {
                messageList.map((item, index) => (
                  <MessageItem key={index} item={item} onClick={(item) => this.clickItem(item)} />
                ))
              }
            </div> : <NoData />
        }
      </div>
    )
  }
}

class MessageItem extends Component {
  clickItem(item) {
    this.props.onClick && this.props.onClick(item)
  }
  render() {
    let { item } = this.props
    return (
      <div className="message-item" >
        <div className="item-content">
          <div className="left-img">
          </div>
          <div className="right-cont">
            <p className="title">{item.title}</p>
            <div className="cont">
              <div dangerouslySetInnerHTML={{ __html: item.text }} />
              <div className="btn" onClick={() => { this.clickItem(item) }}>查看</div>
            </div>
          </div>
        </div>
        <div className="time">{formatTimeMessage(item.receivedAt)}</div>
      </div>
    )
  }
}

class NoData extends Component {
  render() {
    return (
      <div className="no-data">
        <img src={EMPTY_PIC} alt="#" />
        <p>暂无消息</p>
      </div>
    )
  }
}
