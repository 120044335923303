import React, { Component } from 'react'
import { Icon, Menu, PullToRefresh, ListView, ActivityIndicator } from 'antd-mobile'
import CourseItem from '@/components/CourseItem'
import SearchBox from '@/components/SearchBox'
import Apis, { fetchCategories, getCourseList, fetchTracks } from '@/apis/'
import '../styles/courseCenterPage.less'
import TabBarComponent from '@/components/TabBarComponent'
import UQB from '../util/UQB'
import { promisify } from '../util/common'

const CourseApi = Apis.CourseApi
const limit = 9999999
//acs 正序， desc 逆序,默认固定为逆序

const menuList = [
  {
    value: 'updateAt',
    label: '最新',
    st: 'double',
  },
  {
    value: 'played',
    label: '最热',
    st: 'integer',
  },
  {
    value: 'rate',
    label: '好评',
    st: 'double',
  },
]

const gradeList = [
  {
    value: '初级',
    label: '初级',
  },
  {
    value: '中级',
    label: '中级',
  },
  {
    value: '高级',
    label: '高级',
  },
]
const clsList = [
  { value: '', label: '全部' },
  { value: '1', label: '未开始' },
  { value: '3', label: '进行中' },
  { value: '2', label: '已完成' },
]

export default class CourseCenterPage extends Component {
  constructor(props) {
    super(props)
    this.total = 0
    this.hasMore = false
    this.showFooter = false
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    })
    this.state = {
      showFilter: false,
      showSort: false,
      showMask: false,
      showMenu: false,
      dataSource,
      courseList: [],
      title: '',
      sortStatus: 'title',
      categories: [],
      selectCate: [],
      grade: null,
      cls: null,
      rightData: {}, //
      height: document.documentElement.clientHeight,
      page: 0,
      refreshing: false,
      isLoading: false,
      hasMore: false,
      showFooter: false,
      ids: null, //分类下的课程列表查询
      curCategory: null,
    }
  }

  pss = promisify(this.setState.bind(this))

  componentDidMount() {
    document.title = '课程中心'
    const height = this.state.height - 150
    this.setState({
      height,
    })
    CourseApi.getCategories().then(res => {
      //目录的第一层不要，只显示后面的三层，不用递归
      let categories = res.children || []
      let rightData = categories[0] || {}
      this.clickOneLevel(rightData)
      this.setState({ categories, rightData })
    })
    this._getCourseList({ page: 0, sort: this.state.sortStatus })
  }
  _getCourseList = async (obj, isAdd) => {
    let { grade, cls, ids } = this.state
    let postObj = {
      limit: limit,
      order: 'desc',
      grade: grade,
      cls: cls,
      ids,
      category: this.state.curCategory,
      ...obj,
    }

    if (postObj.sort === 'title') {
      postObj.order = 'asc'
    }

    const uqb = new UQB()

    uqb.setSort(obj.sort, postObj.order, menuList.find(m => m.value === obj.sort)?.st || 'varchar')
    uqb.go2page(postObj.page)
    uqb.setPageSize(postObj.limit)
    // !!postObj.cls && uqb.addQuery('author.cls', postObj.cls, 'eq', 'string')
    postObj.title &&
      uqb.addQuery('title', `${encodeURIComponent(`%${postObj.title}%`)}`, 'like', 'string')

    const {
      data: { id },
    } = await fetchCategories()
    let { data, headers } = await getCourseList(postObj.category || id, uqb)
    if (postObj.cls) {
      const { data: tracks } = await fetchTracks()

      data = data.filter(_ => {
        switch (postObj.cls) {
          case '1':
            return tracks.every(track => track.courseId !== _.id)
          case '2':
            return tracks.some(track => track.courseId === _.id && track.finished)
          case '3':
            return tracks.some(track => track.courseId === _.id && !track.finished)
          default:
            return true
        }
      })
    }

    let { page } = this.state
    let courseList = (isAdd ? [...this.state.courseList, ...data] : data) || []
    let total = Number(headers.count) || 0
    if (total >= limit) {
      this.showFooter = true
    } else {
      this.showFooter = false
    }
    if ((page + 1) * limit < total) {
      this.hasMore = true
    } else {
      this.hasMore = false
    }
    this.setState({
      dataSource: this.state.dataSource.cloneWithRows(courseList),
      courseList,
      showFooter: this.showFooter,
      hasMore: this.hasMore,
    })
  }

  onRefresh = async () => {
    let { title, sortStatus, grade, ids } = this.state
    await this.pss({ refreshing: true, isLoading: true, showFooter: false })
    const order = sortStatus === 'title' ? 'asc' : 'desc'
    try {
      await this._getCourseList({ page: 0, limit, order, title, sort: sortStatus, grade, ids })
    } finally {
      await this.pss({
        refreshing: false,
        isLoading: false,
        page: 0,
      })
    }
  }
  onEndReached = async event => {
    let { page, hasMore, isLoading, courseList, title, sortStatus, grade, ids, cls } = this.state
    if (!this.hasMore || isLoading) {
      return
    }
    await this.pss({ isLoading: true })
    //准备分页请求
    const order = sortStatus === 'title' ? 'asc' : 'desc'
    try {
      await this._getCourseList(
        { page: ++page, limit, order, title, sort: sortStatus, grade, ids, cls },
        true
      )
    } finally {
      await this.pss({ isLoading: false, page })
    }
  }
  onSubmit(title) {
    //视图暂没用到，不用更新
    this.setState({
      title: title,
      page: 0,
    })
    this._getCourseList({ page: 0, sort: this.state.sortStatus, title })
  }
  sureGrade() {
    this.setState({
      showFilter: false,
      showMask: false,
    })
    this._getCourseList({
      page: 0,
      sort: this.state.sortStatus,
      title: this.state.title,
      grade: this.state.grade,
      cls: this.state.cls,
    })
  }
  cancelGrade() {
    this.setState(
      {
        grade: null,
        cls: null,
        showFilter: '',
        showMask: false,
      },
      () => {
        this._getCourseList({
          page: 0,
          sort: this.state.sortStatus,
          title: this.state.title,
          grade: this.state.grade,
          cls: this.state.cls,
        })
      }
    )
  }
  onCancel(title) {
    //传的也只是一个空字符串
    this.setState({ title })
  }
  onChange = item => {
    this.setState({
      sortStatus: item[0],
      showSort: false,
      showFilter: false,
      showMask: false,
    })
    this._getCourseList({ page: 0, sort: item[0], title: this.state.title })
  }
  onMaskClick() {
    if (this.state.showFilter) {
      this.cancelGrade()
    }
    this.setState({
      showSort: false,
      showFilter: false,
      showMask: false,
    })
  }
  clickItem(item) {
    let courseId = item.id
    // if (!item.progress) {
    //   item.progress = {}
    // }
    localStorage.setItem('courseItem', JSON.stringify(item))
    this.props.router.push(`/course/detail/${courseId}`)
  }
  clickThirdLevel(item) {
    let { selectCate, ids } = this.state
    selectCate[2] = item.id
    let tmpArr = []

    // 获取选择分类下的所有课程ID集合
    tmpArr = (category => {
      let courses = new Array()
      let stack = new Array()
      stack.push(category)
      while (stack.length > 0) {
        let node = stack.pop()
        courses = courses.concat(...node.courses.map(r => r.id))
        stack.push(...node.children)
      }
      return courses
    })(item)

    if (tmpArr.length) {
      ids = tmpArr.join(',')
    } else if (tmpArr.length === 0) {
      ids = 0
    } else {
      ids = null
    }
    this.setState({
      selectCate,
      showMenu: false,
      ids,
      curCategory: item.id,
    })
    this._getCourseList({
      page: 0,
      sort: this.state.sortStatus,
      title: this.state.title,
      category: item.id,
      ids,
      limit,
      order: 'desc',
    })
  }
  clickOneLevel(item) {
    let { selectCate } = this.state
    selectCate[0] = item.id
    this.setState({
      selectCate,
      rightData: item,
    })
  }
  async cancelMenu() {
    let { selectCate } = this.state
    selectCate[2] = ''
    await this.pss({
      selectCate: selectCate,
      showMenu: false,
      ids: null,
      curCategory: null,
    })
    this._getCourseList({
      page: 0,
      sort: this.state.sortStatus,
      title: this.state.title,
      limit,
      order: 'desc',
      ids: null,
    })
  }
  clickSort() {
    this.setState({
      showSort: true,
      showMask: true,
      showFilter: false,
      showMenu: false,
    })
  }
  clickFilter() {
    this.setState({
      showFilter: true,
      showMask: true,
      showMenu: false,
      showSort: false,
    })
  }
  clickMenu() {
    this.setState({
      showFilter: false,
      showMask: false,
      showMenu: !this.state.showMenu,
      showSort: false,
    })
  }
  render() {
    const {
      dataSource,
      sortStatus,
      categories,
      selectCate,
      rightData,
      showFilter,
      showMenu,
      showSort,
      grade,
      cls,
      showFooter,
      isLoading,
      refreshing,
      hasMore,
    } = this.state
    let menuEl = null
    let sortView = null
    let filterView = null
    if (showMenu) {
      const oneLevelCateView = categories.map((item, index) => {
        return (
          <li
            className={`one-level ${selectCate[0] === item.id ? 'selected' : ''}`}
            key={item.id}
            onClick={() => this.clickOneLevel(item)}
          >
            {item.title}
          </li>
        )
      })

      let rightView = null
      if (rightData.children && rightData.children.length) {
        rightView = rightData.children.map((dtItem, index) => {
          let ddView = null
          selectCate[1] = dtItem.id
          if (dtItem.children && dtItem.children.length) {
            ddView = dtItem.children.map(ddItem => {
              return (
                <dd
                  className={selectCate[2] === ddItem.id ? 'selected' : ''}
                  onClick={() => this.clickThirdLevel(ddItem)}
                  key={dtItem.id}
                >
                  {ddItem.title}
                </dd>
              )
            })
          }
          return (
            <dl key={dtItem.id}>
              <dt>{dtItem.title}</dt>
              <dd
                onClick={() => this.clickThirdLevel(dtItem)}
                className={selectCate[2] === dtItem.id ? 'selected' : ''}
              >
                全部
              </dd>
              {ddView}
            </dl>
          )
        })
      }
      menuEl = (
        <div className='cate-menu'>
          <ul className='left-l'>
            <li className='one-level' onClick={() => this.cancelMenu()}>
              全部
            </li>
            {oneLevelCateView}
          </ul>
          <div className='right-l'>{rightView}</div>
        </div>
      )
    }
    const row = (item, sectionID, rowID) => {
      return (
        <CourseItem
          style={{ wraperStyle: { marginBottom: 0, width: '100%' } }}
          key={item.id}
          item={{
            ...item,
            date: item.updateAt,
            myAuthor: item?.author?.d?.name || item?.author?.name || '暂无',
            myPublisher: item.publisher.name,
          }}
          onClick={item => this.clickItem(item)}
        />
      )
    }
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          background: '#F3F4F8',
          height: 10,
        }}
      />
    )
    if (showSort) {
      sortView = (
        <Menu
          className='sort-menu'
          data={menuList}
          value={[sortStatus]}
          level={1}
          onChange={this.onChange}
          height={document.documentElement.clientHeight * 0.6}
        />
      )
    }
    if (showFilter) {
      filterView = (
        <div className='filter-view'>
          {/* <dl> */}
          {/* <dt>课程级别</dt> */}
          {/* {gradeList.map((item, index) => {
              return (
                <dd
                  key={index}
                  className={grade === item.value ? 'selected' : ''}
                  onClick={() => {
                    if (this.state.grade === item.value) {
                      this.setState({ grade: null, cls: null })
                    } else {
                      this.setState({ grade: item.value, cls: null })
                    }
                  }}
                >
                  {item.label}
                </dd>
              )
            })} */}
          {/* </dl> */}
          <dl>
            {/* <dt>讲师所属</dt> */}
            {clsList.map((item, index) => {
              return (
                <dd
                  key={index}
                  className={cls === item.value ? 'selected' : ''}
                  onClick={() => {
                    if (this.state.cls === item.value) {
                      this.setState({ cls: null, grade: null })
                    } else {
                      this.setState({ cls: item.value, grade: null })
                    }
                  }}
                >
                  {item.label}
                </dd>
              )
            })}
          </dl>
          <div className='btn-box'>
            <span className='cancel' onClick={() => this.cancelGrade()}>
              取消
            </span>
            <span className='sure' onClick={() => this.sureGrade()}>
              确认
            </span>
          </div>
        </div>
      )
    }

    return (
      <div className='course-center-page'>
        <div className='header bottom-line'>
          {/* <span className="menu-icon" onClick={() => { this.clickMenu() }}></span> */}
          <SearchBox
            name={'course-search-box'}
            placeholder='请输入要搜索的课程'
            onCancel={() => this.onCancel()}
            onSubmit={value => this.onSubmit(value)}
          />
        </div>
        <div className='condition-box bottom-line'>
          <div
            className='sort-box'
            onClick={() => {
              this.clickMenu()
            }}
          >
            课程类别
            <Icon type='down' color='#979DB2' />
          </div>
          <div
            className='sort-box'
            onClick={() => {
              this.clickSort()
            }}
          >
            排序
            <Icon type='down' color='#979DB2' />
          </div>
          <div
            className='filter-box'
            onClick={() => {
              this.clickFilter()
            }}
          >
            筛选<em className='filter-icon'></em>
          </div>
        </div>

        <ListView
          ref={el => (this.lv = el)}
          dataSource={dataSource}
          renderRow={row}
          class='listView'
          renderSeparator={separator}
          style={{
            height: this.state.height,
          }}
          renderFooter={() => {
            if (showFooter) {
              return (
                <div
                  style={{
                    padding: 10,
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {isLoading ? (
                    <ActivityIndicator text='loading...' />
                  ) : hasMore ? (
                    '上拉加载更多'
                  ) : (
                    '没有更多了'
                  )}
                </div>
              )
            }
          }}
          pullToRefresh={<PullToRefresh refreshing={refreshing} onRefresh={this.onRefresh} />}
          onEndReached={this.onEndReached}
          onEndReachedThreshold={10}
          pageSize={limit}
        />
        {menuEl}
        {sortView}
        {filterView}
        {this.state.showMask ? (
          <div className='menu-mask' onClick={() => this.onMaskClick()} />
        ) : null}
        <TabBarComponent selectedTab={'Course'} />
      </div>
    )
  }
}
