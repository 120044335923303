import React from 'react';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';

import App from '../App';
import Login from '../pages/LoginPage';
import One from '../pages/OnePage';
import Home from '../pages/HomePage';
import ForgetPw from '../pages/ForgetPwPage';
import PersonCenter from '../pages/PersonCenterPage';
import ConfigPage from '../pages/ConfigPage';
// import IndexWraper from '../components/IndexWraper'
import TaskPage from '../pages/TaskPage';
import LastestCoursePage from '../pages/LastestCoursePage';
import RecommendCoursePage from '../pages/RecommendCoursePage';
import RankPage from '../pages/RankPage';
import TestCenterPage from '../pages/TestCenterPage';
import CourseCenterPage from '../pages/CourseCenterPage';
import NotFountPage from '../pages/404Page';
import CourseDetailPage from '../pages/CourseDetailPage';
import MyCollectionPage from '../pages/MyCollectionPage';
import TrainDetailPage from '../pages/TrainDetailPage';
import TrainPage from '../pages/TrainPage';
import MyMessage from '../pages/MyMessage';
import MessageDetail from '../pages/MessageDetail';
import Author from '../pages/Author';
import Startup from '../pages/startup';
import Wx from '../pages/wx';
import Undevelop from '../pages/unDevelop';
import Feedback from '../pages/Feedback';
import PdfDetail from '../pages/PdfDetail';
import ReportPage from '../pages/ReportPage';
import XpPage from '../pages/XpPage';
import WIPPage from '../pages/WIPPage';
import DevInfoPage from '../pages/DevInfoPage';

export default () => (
    <Router history={browserHistory}>
        <Route path='/startup' component={Startup} />
        <Route path='/wx' component={Wx} />
        <Route path='/' component={App}>
            <IndexRoute component={Home} />
            <Route path='/personcenter' component={PersonCenter} />
            <Route path='/task' component={TaskPage} />
            <Route path='/forgetPw' component={ForgetPw} />
            <Route path='/login' component={Login} />
            <Route path='/one' component={One} />
            <Route path='/lastest/course' component={LastestCoursePage} />
            <Route path='/recommend/course' component={RecommendCoursePage} />
            <Route path='/rank' component={RankPage} />
            <Route path='/course' component={CourseCenterPage} />
            <Route path='/course/detail/:id' component={CourseDetailPage} />
            <Route path='/train/detail/:id' component={TrainDetailPage} />
            <Route path='/train' component={TrainPage} />
            <Route path='/test' component={TestCenterPage} />
            <Route path='/config' component={ConfigPage} />
            <Route path='/collection' component={MyCollectionPage} />
            <Route path='/message' component={MyMessage} />
            <Route path='/messagedetail' component={MessageDetail} />
            <Route path='/author' component={Author} />
            <Route path='/feedback' component={Feedback} />
            <Route path='/undevelop' component={Undevelop} />
            <Route path='/pdfDetail/:url' component={PdfDetail} />
            <Route path='/report' component={ReportPage} />
            <Route path='/xp' component={XpPage} />
            <Route path='/wip' component={WIPPage} />
            <Route path='/dev_info' component={DevInfoPage} />
            <Route path='/*' component={NotFountPage} />
        </Route>
    </Router>
);
