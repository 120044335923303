import React, { Component } from 'react';
import { List, Toast, NavBar, Icon, Button } from 'antd-mobile';
import { browserHistory } from 'react-router';
import '../styles/configPage.less';
import Apis from '@/apis/';
import ResetPw from '../components/ResetPw';
import 'cropperjs/dist/cropper.css';
import Cropper from 'react-cropper';

const UserApi = Apis.UserApi;
const Item = List.Item;

export default class ConfigPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            showReset: false,
            showAvatar: false,
        };
    }
    componentDidMount() {
        document.title = '设置';
        this.getUserInfo();
    }
    getUserInfo() {
        UserApi.queryUserProp().then(res => {
            this.setState({
                info: res,
            });
        });
    }
    logout() {
        //如果socket存在，则关闭连接
        if (window.socket) {
            window.socket.close();
        }
        // sessionStorage.setItem('userInfo', '');
        localStorage.setItem('userInfo', '');
        browserHistory.push('/login');
    }

    render() {
        const { avatar } = this.state.info;
        return (
            <div className="config-page">
                <List>
                    <Item
                        arrow="horizontal"
                        className="item"
                        onClick={() => {
                            this.setState({ showAvatar: true });
                        }}
                    >
                        {/* <img src={avatar} alt='avatar' className='avatar' /> */}
                        修改头像
                    </Item>
                    <Item
                        arrow="horizontal"
                        className="item not-line"
                        onClick={() => {
                            this.setState({ showReset: true });
                        }}
                    >
                        修改密码
                    </Item>
                </List>
                <div className="btn-box" onClick={() => this.logout()}>
                    退出登录
                </div>
                {this.state.showReset ? <ResetPw showReset={flag => this.setState({ showReset: flag })} info={this.state.info} /> : null}
                {this.state.showAvatar ? (
                    <AvatarView
                        avatar={avatar}
                        onSure={() => {
                            this.getUserInfo();
                        }}
                        showAvatar={flag => this.setState({ showAvatar: flag })}
                    />
                ) : null}
            </div>
        );
    }
}

class AvatarView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatar: '',
            showCroper: false,
        };
    }
    componentDidMount() {
        const { avatar } = this.props;
        this.setState({ avatar });
    }
    setChangeAvatar() {
        let avatar;
        avatar = this.getObjectURL(this.file);
        this.setState({
            avatar,
            showCroper: true,
        });
    }
    onClickInput() {
        let file = document.getElementById('file').files[0];
        this.file = file;
        this.setChangeAvatar();
    }
    getObjectURL(file) {
        var url = null;
        // 下面函数执行的效果是一样的，只是需要针对不同的浏览器执行不同的 js 函数而已
        if (window.createObjectURL !== undefined) {
            // basic
            url = window.createObjectURL(file);
        } else if (window.URL !== undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file);
        } else if (window.webkitURL !== undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file);
        }
        return url;
    }
    sure() {
        const { showAvatar, onSure } = this.props;
        if (!this.modifyOnce) {
            this.modifyOnce = true;
            UserApi.modifyAvatar({
                url: this.state.avatar,
            })
                .then(res => {
                    this.modifyOnce = false;
                    Toast.info('更新成功', 2, null, false);
                    onSure();
                    showAvatar(false);
                })
                .catch(() => {
                    this.modifyOnce = false;
                });
        }
    }
    cropperCancel() {
        const { avatar } = this.props;
        document.getElementById('file').value = '';
        this.file = '';
        this.setState({
            avatar,
            showCroper: false,
        });
    }
    // 保存当前
    cropperSure() {
        const croppedCanvas = this.cropper.getCroppedCanvas();
        if (typeof croppedCanvas === 'undefined') {
            Toast.info('裁剪失败', 2, null, false);
            return;
        }
        croppedCanvas.toBlob(async blob => {
            // 创造提交表单数据对象
            const formData = new FormData();
            // 添加要上传的文件
            formData.append('file', blob, this.file.name);
            // 获取 org property 中的 avatar 限制
            let avatar = 5 * 1024 * 1024;
            try {
                const res = await UserApi.getCommonConfig();
                res.avatar && (avatar = res.avatar);
            } catch (e) {
                console.error(e);
            }
            if (avatar < blob.size) {
                Toast.fail(`上传大小不能超过${avatar / (1024 * 1024)}M`);
                return;
            }

            UserApi.uploadAvatar(formData)
                .then(res => {
                    let avatar = res.url;
                    Toast.info('裁剪成功', 2, null, false);
                    this.setState({
                        avatar,
                        showCroper: false,
                    });
                })
                .catch(err => {
                    console.log(err);
                    err.response && err.response.data && Toast.fail(err.response.data, 2, null, false);
                });
        });
    }
    render() {
        const { showAvatar } = this.props;
        const { avatar, showCroper } = this.state;

        const CropperView = (
            <div className="cropper-box">
                {/* <div className="uploadCrop avatar" /> */}
                <Cropper
                    style={{ width: '100%', height: '50%', transform: 'translateY(50%)' }}
                    aspectRatio={1}
                    preview=".uploadCrop"
                    guides={false}
                    src={this.state.avatar}
                    ref={cropper => {
                        this.cropper = cropper;
                    }}
                />
                <div className="btn-box">
                    <span onClick={() => this.cropperCancel()}>取消</span>
                    <span onClick={() => this.cropperSure()}>完成</span>
                </div>
            </div>
        );
        return (
            <div className="avatar-edit-box">
                <NavBar
                    mode="light"
                    key={1}
                    icon={
                        <div className="nav-left">
                            <Icon type="left" />
                            <em>取消</em>
                        </div>
                    }
                    onLeftClick={() => showAvatar(false)}
                    rightContent={[
                        <div key={1} onClick={() => this.sure()}>
                            确认
                        </div>,
                    ]}
                />
                <div className="avatar">
                    <img src={avatar + '?r=' + Math.random()} alt="#" />
                </div>
                <div className="input-box">
                    <Button size="small">选择图片</Button>
                    <input type="file" accept="image/*" onChange={() => this.onClickInput()} id="file" className="input" />
                </div>
                {showCroper ? CropperView : null}
            </div>
        );
    }
}
