import { axiosget, axiosput, axiospost, axiosdelete } from '../config/axios'
import ins from '../config/interceptors'

const prefix = '/courseinterface/v1'
//获取课程的目录
export function getCategories(data) {
  return axiosget(`/courseinterface/v1/categories`, data)
}

//获取用户的课程
export function getUserCourse(data) {
  return axiosget(`${prefix}/users/${data.userId}/courses`, data)
}

//课程中心的课程列表
export function getCourseList(data) {
  return axiosget('/courseinterface/v1/courses', data)
}

//获取课程的详情
export function getCourseInfo(courseId, data) {
  return axiosget(`${prefix}/courses/${courseId}`, data)
}

//获取学习计划
export function getPlanList(data) {
  return axiosget(`traineeinterface/v1/course-tracks`, data)
}
//加入学习计划
export function joinPlan(data) {
  return axiospost(`/traineeinterface/v1/course-tracks`, data)
}

/**
 * @description learnTime 单位为秒
 * @export
 * @param {*} id
 * @param {{
 * userDefinedTrack: {
 *  [paragraphId: string]: {
 *    learnTime: number;
 *    durationMillis: number;
 *    positionMillis: number;
 *    finish: boolean;
 *   }
 *  }
 * }} data
 * @returns
 */
export function updateCourseTrack(id, data) {
  return ins.patch(`/traineeinterface/v1/course-tracks/${id}`, data)
}

//删除学习计划
export function delePlan(courseId, data) {
  return axiosdelete(`/traineeinterface/v1/course-tracks/${courseId}`, data)
}
//更改进度 （这是加入学习计划的课程进度更新）
export function changePlanCourseProgress(id, data) {
  return ins.post(`/traineeinterface/v1/course-tracks/${id}/progresses`, data + '', {
    headers: { 'Content-Type': 'text/plain' },
  })
}
//改变课程状态，以及章节进度等(这是改变培训档案里的进度更新)
export function changeTrainCourseProgress(id, courseId, data) {
  return axiosput(`/traineeinterface/v1/studyplans/${id}/courses/${courseId}/progress`, data)
}

//收藏课程
export function collectCourse(id, data) {
  return axiosput(`/traineeinterface/v1/courses/${id}/favorite`, data)
}

//获取推荐的课程列表
export function getRecommendCourseList(data) {
  return axiosget(`courseinterface/v1/recommendation`, data)
}

//获取已收藏的课程列表
export function getCollectedCourse(data) {
  return axiosget(`/traineeinterface/v1/favorites`, data)
}

//评价课程几星
export function valuateCourse(courseId, data) {
  return axiosput(`/courseinterface/v1/courses/${courseId}/rate`, data)
}

//点击播放此处累加
export function changePlayed(courseId, data) {
  return axiosput(`/courseinterface/v1/courses/${courseId}/played`, data)
}

// 播放时长的接口
export function changeStudyTime(courseId, data) {
  return axiosput(`/traineeinterface/v1/courses/${courseId}/duration`, data)
}

export default {
  getCategories,
  getUserCourse,
  getCourseInfo,
  getCourseList,
  getPlanList,
  changePlanCourseProgress,
  changeTrainCourseProgress,
  collectCourse,
  joinPlan,
  delePlan,
  getCollectedCourse,
  getRecommendCourseList,
  valuateCourse,
  changePlayed,
  changeStudyTime,
}
